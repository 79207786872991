import { Component, OnInit } from '@angular/core';
import { ICard, IPopupConfigs, IPopupCpmmands, IUser } from '../ds-components/ds-types';
import { ProfileService } from '../profile.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ExtapiService } from '../extapi.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {


  cards: ICard[] = [
    {
      icon: './assets/dasboard/home.png',
      title: 'Entries',
      info: '35',
      infoIcon: ''
    },
    {
      icon: './assets/dasboard/users.png',
      title: 'Cash Spent',
      info: '$3,435',
      infoIcon: ''
    },
    {
      icon: './assets/dasboard/users.png',
      title: 'Successful Invites',
      info: '189',
      infoIcon: ''
    },
    {
      icon: './assets/dasboard/settings.png',
      title: 'Bid Placed',
      info: '50',
      infoIcon: ''
    },
    {
      icon: './assets/dasboard/send.png',
      title: 'Contracts Won',
      info: '187',
      infoIcon: ''
    }
  ]

  popupData: IPopupConfigs[];

  currentPopupPage: IPopupConfigs;
  showPopupWindow: boolean = false;
  width: number = 500;
  height: number = 500;


  currentUser: IUser;
  
  image: string | SafeUrl
  coverimage = null; 
  // password;
  allRegistredAuctions;

  constructor(
    private profileService: ProfileService,
    private sanitizer: DomSanitizer,
    public extapiService: ExtapiService) { 

    this.currentUser = profileService.getUser();
    // this.password = atob(this.currentUser.password)

    
  }

  async ngOnInit(): Promise<void> {

    this.popupData = [
      {
        header: 'Change Phone Number',
        contentHeader: 'Choose a reason',
        content: `phone`,
        bckButton: false,
        nxtButton: false,
        customContents: 'change-phone-number',
        showFooter: false,
        nxtButtonDis: false
      },
      {
        header: 'Change Address',
        contentHeader: 'Choose a reason',
        content: `address`,
        bckButton: false,
        nxtButton: false,
        customContents: 'change-address',
        showFooter: false,
        nxtButtonDis: false
      }
      
    ];

    this.image = this.currentUser.profileImage;

    //set cards info
    this.allRegistredAuctions = await this.extapiService.getAllRegisteredAuctionsDetailsPerUser({clientId: this.currentUser.client_id});
    
    this.cards[0].info = this.allRegistredAuctions.length;
    this.cards[1].info = "$" + this.allRegistredAuctions.reduce((a, c) => {return a + c.enter_fee}, 0).toLocaleString();

    let allBids = await this.extapiService.getBidsByClientID({client_id: this.currentUser.client_id});
    this.cards[3].info = allBids.length;

    let WinningDetailsArr = await this.extapiService.getWinningDetailsPerUser({client_id: this.profileService.currentUser.client_id});
    this.cards[4].info  = WinningDetailsArr.length;

  }

  recieveFromPopupDialog(e){
    
    switch(e.command){

      case IPopupCpmmands.close:
        this.showPopupWindow = false;
        

      break;
      case IPopupCpmmands.submit:

        this.showPopupWindow = false;

      break;

    }
    

  }

  changeAddress(){

    this.currentPopupPage = this.popupData[1];
    this.showPopupWindow = true;

  }

  changePhoneNumber(){

    this.currentPopupPage = this.popupData[0];
    this.showPopupWindow = true;

  }


  async updateImage(ev) {

    this.image = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(ev.target.files[0])
    );

    this.coverimage = await this.convertBase64(ev.target.files[0]);

    this.updateProfileImage();

    
    
  }

  convertBase64 = (file) => {

    return new Promise((resolve, reject) => {

        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = () => {

            resolve(fileReader.result.toString());
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
  }


  async deleteProfileImage(){

    try {

      await this.extapiService.updateUserprofileImage({email:this.currentUser.email,image:null});
      this.image = "./assets/Default-Profile-Picture.png";
      this.coverimage = "./assets/Default-Profile-Picture.png";
      this.currentUser.profileImage = "./assets/Default-Profile-Picture.png";
      alert("Successfully delete your profile photo")
      
    }
     
    catch (error) {

      console.log(error);
      alert("Something went wrong")
      
    }

  }

  async updateProfileImage(){

    if(this.coverimage !== null){

      try {

        this.currentUser.profileImage = this.coverimage;
        await this.extapiService.updateUserprofileImage({email:this.currentUser.email,image:this.currentUser.profileImage});
        alert("Successfully updated your profile photo")
        
      }
       
      catch (error) {
  
        console.log(error);
        alert("Something went wrong")
        
      }
    }

    else{

      alert("Please select a image")
    }

    



  }

}
