import { Injectable } from '@angular/core';
import { IUser } from './ds-components/ds-types';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

	public currentUser: IUser;

    constructor() { 
     
      this.currentUser = {
        displayName: "Developer",
        email: "example@gmail.com",
        fullName: "Developer",
        profileImage: "./assets/profile/pro-pic.png",
        client_id: 1234,
        address: "St George's Ln Singapore",
        date_of_birth: "1992/07/24",
        phone_number: "+94773246208",
        date:"2023-11-10 14:48:57",
        password:"123"
      }
    }

    setUser (userDetails :IUser): void {

      this.currentUser = {
        displayName: userDetails.displayName || "Developer",
        email: userDetails.email || "example@gmail.com",
        fullName: userDetails.fullName || "Developer",
        profileImage: userDetails.profileImage || "./assets/Default-Profile-Picture.png",
        client_id: userDetails.client_id || 1234,
        address: userDetails.address ||"St George's Ln Singapore",
        date_of_birth: userDetails.date_of_birth || "1992/07/24",
        phone_number:  userDetails.phone_number || "+94773246208",
        date:  userDetails.date ||"2023-11-10 14:48:57",
        password: userDetails.password || "123",
      }
        
    }

    getUser(): IUser{
        return this.currentUser;
    }
    

}