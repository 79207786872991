<div class="tab-panel">

	<p-tabView>

		<p-tabPanel header="Security">

			<div class="security-container">

				<div class="title">SET PASSWORD</div>

				<div class="new-password">

					<div class="new-password-title">New Password</div>
					<input id="input" type="text" size="30" pInputText>

				</div>

				<div class="confirm-password">

					<div class="confirm-password-title">Confirm Password</div>

					<div class="confirm-password-text">
						<input id="input" type="text" size="50" pInputText> 
						<div class="hint">8 characters or longer. Combine upper and lowercase letters and numbers.</div>
					</div>

				</div>

				<div class="save-changes">
					<button pButton type="button" label="Save Changes" >Save Changes</button>
				</div>
				
				<div class="phone-verification">

					<div class="phone-verification-title">PHONE VERIFICATION</div>

					<div class="phone-verification-text">

						<div class="phone-verification-hint">Your phone is verified with DreamStakes. Click Edit to change your phone number</div> 
					
            <div class="button">
              <button pButton type="button" label="Edit">Edit</button>
            </div>

          </div>
          
				</div>
				
				<div class="security-question">

					<div class="security-question-title">SECURITY QUESTION</div>

					<div class="security-question-text">

						<div class="security-question-hint">
							By creating a security question, you will add an additional layer of 
							protection for your revenue withdrawals and for changing your 
							password.
						</div>

						<div class="button">
              <button pButton type="button" label="Edit">Edit</button>
            </div>

					</div>

				</div> 

			</div>

		</p-tabPanel>

		<!-- <p-tabPanel header="Notifications">
				
			<div class="notification-container">

        <div class="notification-title">NOTIFICATIONS</div>

        <div class="notification-text">For important updates regarding your DreamStakes activity, certain notifications cannot be disabled.</div>

        <div class="notification-grid">

          <div class="grid-titles">

            <div class="type">Type</div>
            <div class="email">Email</div>
            <div class="text">Text</div>

          </div>

          <div class="data-row">
            
            <div class="type-data">Winner Email</div>

            <div class="email-data">
              <p-checkbox name="row1"  inputId="1"></p-checkbox>
            </div>

            <div class="text-data">
              <p-checkbox name="row1"  inputId="2"></p-checkbox>
            </div>

          </div>

          <div class="data-row">
            <div class="type-data">New Entry</div>

            <div class="email-data">
              <p-checkbox name="row1"  inputId="1"></p-checkbox>
            </div>

            <div class="text-data">
              <p-checkbox name="row1"  inputId="2"></p-checkbox>
            </div>

          </div>

          <div class="data-row">

            <div class="type-data">New Bid</div>

            <div class="email-data">
              <p-checkbox name="row1"  inputId="1"></p-checkbox>
            </div>

            <div class="text-data">
              <p-checkbox name="row1"  inputId="2"></p-checkbox>
            </div>

          </div>

          <div class="data-row">
            <div class="type-data">Eligibility Agreement</div>
            <div class="email-data">
              <p-checkbox name="row1"  inputId="1"></p-checkbox>
            </div>

            <div class="text-data">
              <p-checkbox name="row1"  inputId="2"></p-checkbox>
            </div>
          </div>

          <div class="data-row">
            <div class="type-data">Verfication Status</div>
            <div class="email-data">
              <p-checkbox name="row1"  inputId="1"></p-checkbox>
            </div>

            <div class="text-data">
              <p-checkbox name="row1"  inputId="2"></p-checkbox>
            </div>
          </div>

          <div class="data-row">
            <div class="type-data">Initial Meeting</div>
            <div class="email-data">
              <p-checkbox name="row1"  inputId="1"></p-checkbox>
            </div>

            <div class="text-data">
              <p-checkbox name="row1"  inputId="2"></p-checkbox>
            </div>
          </div>

          <div class="data-row">
            <div class="type-data">New Entry</div>
            <div class="email-data">
              <p-checkbox name="row1"  inputId="1"></p-checkbox>
            </div>

            <div class="text-data">
              <p-checkbox name="row1"  inputId="2"></p-checkbox>
            </div>
          </div>

          <div class="data-row">
            <div class="type-data">Eligibility Timer</div>
            <div class="email-data">
              <p-checkbox name="row1"  inputId="1"></p-checkbox>
            </div>

            <div class="text-data">
              <p-checkbox name="row1"  inputId="2"></p-checkbox>
            </div>
          </div>

          <div class="data-row">
            <div class="type-data">Project Schedule Benchmarks</div>
            <div class="email-data">
              <p-checkbox name="row1"  inputId="1"></p-checkbox>
            </div>

            <div class="text-data">
              <p-checkbox name="row1"  inputId="2"></p-checkbox>
            </div>
          </div>


        </div>

			</div>

		</p-tabPanel> -->

		<p-tabPanel header="Payment-methods">
				
			<div class="payment-methods-container">

        <div class="payment-methods-title">PAYMENT METHODS</div>

          <div class="payment-methods-body">

            <div class="paypal">

              <div class="text1">Paypal</div>
              <div class="text2">i*******d@gmail.com</div>
              <div class="text3" (click)="showPopup(settingPopupScreen.changePayPal, 'hidden')">Change</div>

            </div>

            <div class="visa-card">
              <div class="text1">Visa card</div>
              <div class="text2">8*** 5*** 4*** 7***</div>
              <div class="text3" (click)="showPopup(settingPopupScreen.changePayPal, 'hidden')">Change</div>
            </div>

            <div class="add-new" (click)="showPopup(settingPopupScreen.addNewCard, 'visible')">Add a new visa card</div>

          </div>

      </div>

		</p-tabPanel>

	</p-tabView>
  
</div>