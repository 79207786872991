import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-rules-and-reg',
  templateUrl: './rules-and-reg.component.html',
  styleUrls: ['./rules-and-reg.component.scss']
})
export class RulesAndRegComponent implements OnInit {

  @Input() display: boolean = false;
  @Input() width: number;
  @Input() height: number;

  @Output() closeRulesPopup = new EventEmitter<any>();
 
  

  constructor() { }

  ngOnInit(): void {
  }

  close(){

    this.display = false;
    this.closeRulesPopup.emit();
    
  }


}
