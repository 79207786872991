<div style="width: 100%; text-align: center;">

    <div style="font-family: 'Open Sans'; font-style: normal; font-weight: 700; font-size: 32px; line-height: 28px; color: #2C2C2C; margin-bottom: 15px;">
       {{auctionDetails?.title}}
    </div>

    <div style="font-family: 'Open Sans'; font-style: normal; font-weight: 600; font-size: 20px; line-height: 28px; color: #2C2C2C; margin-bottom: 20px;">
        {{auctionDetails?.type}} Auction House
    </div>

    <div style="font-family: 'Open Sans'; font-style: normal; font-weight: 400; font-size: 20px; line-height: 28px; color: #2C2C2C; margin-bottom: 40px;">
        Current Bid : ${{heighestBid}}
    </div>

  </div>

  <div style="width: 100%; height: 0px; border: 1px solid rgba(44, 44, 44, 0.2); margin-bottom: 56px;"></div>

  <div style="width: 100%;display: flex; flex-direction: column; justify-content: flex-start; align-items: center;">

    <label style="
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 28px;
        color: #F9A369;
    ">
        Success
    </label>

    <label style="
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: justify;
        color: #2C2C2C;
        margin-top: 21px;
    ">
        Your Bid for ${{BiddingAmount}} is now live
    </label>

    <label style="
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        text-align: justify;
        color: #2C2C2C;
        margin-top: 80px;
    ">
        Share Your Ask
    </label>

    <div style="display: flex; flex-direction: row; justify-content: space-between; width: 267px; height: 48px; margin-top: 35px;">

      <img style="width: 48px; height: 48px;" src="./assets/dasboard/icons/fb.png">

      <img style="width: 48px; height: 48px;" src="./assets/dasboard/icons/twitter.png">

      <img style="width: 48px; height: 48px;" src="./assets/dasboard/icons/pinterest.png">

      <img style="width: 48px; height: 48px;" src="./assets/dasboard/icons/google_plus.png">

    </div>

  </div>
