import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IMessageStream, Interconnect } from 'ng-interconnect';
import { MainViews } from 'src/app/app.types';
import { IPopupConfigs } from '../../ds-types';
import { ProfileService } from 'src/app/profile.service';
import { ExtapiService } from 'src/app/extapi.service';
import { AppConfigService } from 'src/app/app-config.service';
import * as moment from 'moment';
import { SafeHtmlPipe } from 'src/app/safe-html.pipe';

@Component({
  selector: 'app-new-contract-popup',
  templateUrl: './new-contract-popup.component.html',
  styleUrls: ['./new-contract-popup.component.scss'],
  providers: [SafeHtmlPipe]
})
export class NewContractPopupComponent implements OnInit {

  @Input() display: boolean = false;
  @Input() popupData: IPopupConfigs;
  @Input() width: number;
  @Input() height: number;

  URIBlob;
  text2;
  registedUsersDetails;

  @Output() closePopup = new EventEmitter<any>();

  private changeView: IMessageStream | Promise<IMessageStream>;

  agreeToTermsCondition: boolean = false;
  agreeToPrivacyPolicy: boolean = false;

  showAddCardPopup: boolean = false;

  constructor(
    private interconnect: Interconnect,
    public profileService: ProfileService,
    public extapiService: ExtapiService,
    public appService: AppConfigService,
    private safe: SafeHtmlPipe
  ) {

    this.changeView = interconnect.connectToListener('leftbar/changeView', 'topbar');
    if (this.changeView['then']) {
      this.changeView['then']((notifier) => this.changeView = notifier);
    }

  }

  ngOnInit(): void {

    this.showPdfData();

  }

  close(){

    this.display = false;
    this.closePopup.emit();

  }

  async checkForPaymentsAndProcess(){
    
    let cardDetails = JSON.parse(localStorage.getItem("ds-debit"));

    if(!cardDetails)
      this.showAddCardPopup = true;
    
    else{

      try {

        let selectectAuction = this.appService.allAuctions[this.appService.selectedAuctionIdx];

        let date = moment().format("YYYY-MM-DD hh:mm:ss");
        let endedTime = moment().add(2, 'days');

        //Registering a new user for the current auction
        await this.extapiService.registerAuction({
          auction_id: selectectAuction.auction_id,
          client_id: this.profileService.currentUser.client_id,
          category: selectectAuction.category,
          room_number: 1,
          enter_fee: selectectAuction.enter_fee,
          date: date
        });

        //Get number of registed users in current auction
        this.registedUsersDetails = await this.extapiService.getNumbersOfUsersForRegistedAuction({auctionId: selectectAuction.auction_id});

        //Checking if the maximum number of registrants are currently registered for the selected auction.
        if(this.registedUsersDetails[0].NumberOfUsers === selectectAuction.entrants){
          
          //send aucion timmer activation details to backend
          await this.extapiService.auctionTimmerActivation({
            auction_id: selectectAuction.auction_id,
            room_number: 1,
            started_time: date,
            ended_time: endedTime.format("YYYY-MM-DD hh:mm:ss") 
          })
    
        }
    
        this.close();
        (this.changeView as IMessageStream).emit({viewId: MainViews.dashboard, showBackground: true, showCards:false});
        
      } 
      
      catch (error) {

        console.log(error);
        alert("Something went wrong")
        
      }

      

    }


  }

  closeCardPopup(e){
    
    this.showAddCardPopup = false;
    (this.changeView as IMessageStream).emit({viewId: MainViews.dashboard, showBackground: true, showCards:false});
    
  }

  showPdfData(){
    
    // let binData = base64ToArrayBuffer(this.popupData?.content.split(",")[1]);
    // let blob = new Blob([binData], { type: 'application/pdf' });
    // this.URIBlob = this.safe.transform(window.URL.createObjectURL(blob));

    this.URIBlob = this.safe.transform(this.popupData?.content + "#toolbar=0");

  }

}


function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}