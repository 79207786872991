import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonSliderComponent } from './common-slider/common-slider.component';
import { CardSliderComponent } from './card-slider/card-slider.component';
import { PopupComponent } from './popup/popup.component';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { SafeHtmlPipe } from '../safe-html.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { NotificationComponent } from './notification/notification.component';
import { InboxComponent } from './inbox/inbox.component';
import { NgxCcModule } from 'ngx-cc';
import {MatFormFieldModule} from '@angular/material/form-field';
import { CustomBidComponent } from './popups/custom-bid/custom-bid.component';
import { FinishBidComponent } from './popups/finish-bid/finish-bid.component';
import { DsContractComponent } from './popups/ds-contract/ds-contract.component';
import { NewContractPopupComponent } from './popups/new-contract-popup/new-contract-popup.component';
import { AddCardPopupComponent } from './popups/add-card-popup/add-card-popup.component';

import {EditorModule} from 'primeng/editor';
import { RulesAndRegComponent } from './popups/rules-and-reg/rules-and-reg.component';




@NgModule({
  declarations: [
    CommonSliderComponent, 
    CardSliderComponent, 
    PopupComponent, 
    SafeHtmlPipe, 
    NotificationComponent, 
    InboxComponent, 
    CustomBidComponent, 
    FinishBidComponent, 
    DsContractComponent,
    NewContractPopupComponent,
    AddCardPopupComponent,
    RulesAndRegComponent,
  ],
  imports: [
    CommonModule,
    DialogModule,
    CheckboxModule,
    RadioButtonModule,
    ButtonModule,
    FormsModule,
    AgGridModule,
    NgxCcModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    EditorModule

  ],
  exports: [
    CommonSliderComponent,
    CardSliderComponent,
    PopupComponent,
    NotificationComponent,
    InboxComponent,
    CustomBidComponent,
    DsContractComponent,
    NewContractPopupComponent,
    AddCardPopupComponent,
    RulesAndRegComponent
  ]
})
export class DsComponentsModule { }
