import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';

import { IPopupConfigs, IPopupCpmmands } from '../ds-types';
import { ExtapiService } from 'src/app/extapi.service';
import { AgGridAngular } from 'ag-grid-angular';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopupComponent implements OnInit, AfterViewInit {

  @Input() display: boolean = false;
  @Input() popupData: IPopupConfigs;
  @Input() width: number;
  @Input() height: number;
  @Input() selectedAuctionId: number;
  @Input() selectedAuctionRoomNumber: number;
  @Input() selectedClosedAuctionId: number;
  @Input() selectedClosedAuctionRoomNumber: number;

  @Output() notifyParent = new EventEmitter<any>();

  @ViewChild('observerGrid') observerGrid: AgGridAngular;
  @ViewChild('reviewGrid') reviewGrid: AgGridAngular;

  

  contentLines;
  // paymenttype: string = '';

  observeColumnDefs = [
    {  
      headerName: 'No',
      field: 'No',
      width: 200,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'User Name',
      field: 'UserName',
      width: 400,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'Last Bid',
      field: 'LastBid',
      width: 350,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'Increment',
      field: 'Increment',
      width: 350,
      resizable: true,
      sort: 'asc',
      cellRenderer: this.incrementRenderer
    }
  ];

  observeRowData = [];

  reviewColumnDefs = [
    {  
      headerName: 'Active Bidder',
      field: 'ActiveBidder',
      width: 450,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'Total Bid Activity',
      field: 'TotalBidActivity',
      width: 350,
      resizable: true,
      sort: 'asc'
    },
    {  
      headerName: 'Hold Time',
      field: 'HoldTime',
      width: 350,
      resizable: true,
      sort: 'asc'
    }
  ];

  reviewRowData = [
    // {
    //   ActiveBidder: 'Esther Howard',
    //   TotalBidActivity: '$319.25',
    //   HoldTime: '20h 45m'
    // }

  ];


  constructor(private extAPI: ExtapiService) { }

  ngOnInit() {


  }

  async ngAfterViewInit(){

    //observe grid data
    if(this.selectedAuctionId){

      let LastActiveBids = await this.extAPI.getLastTenBids({
        "auctionId": this.selectedAuctionId,
        "current_room": this.selectedAuctionRoomNumber
      });
  
      LastActiveBids.forEach(el => {
        this.observeRowData.push(
          {
            No: el.no,
            UserName: el.user_name,
            LastBid: "$" + el.last_bid,
            Increment: el.increment
          }
        )
      })
  
      this.observerGrid.api.setRowData(this.observeRowData);

    }

    //review grid data
    if(this.selectedClosedAuctionId){
    
    let LastClosedBids = await this.extAPI.getLastTenBidsInClosedAuction({
      "auctionId": this.selectedClosedAuctionId,
      "current_room": this.selectedClosedAuctionRoomNumber
    });

    LastClosedBids.forEach(el => {
      this.reviewRowData.push(
        {
          UserName: el.user_name,
          ActiveBidder: el.user_name,
          TotalBidActivity: "$" + el.last_bid,
          HoldTime: el.diff
        }
      )
    })

    this.reviewGrid.api.setRowData(this.reviewRowData);


    }

  }

  close(customClose){
    this.sendCommandToParent(IPopupCpmmands.close, customClose);
  }

  sendCommandToParent(command, customCommand = false){
    
    switch(command){
      case IPopupCpmmands.close: this.notifyParent.emit({command: IPopupCpmmands.close, customCommand: 'closeTearms'}); break;
      case IPopupCpmmands.next: this.notifyParent.emit({command: IPopupCpmmands.next}); break;
      case IPopupCpmmands.back: this.notifyParent.emit({command: IPopupCpmmands.back}); break;
      case IPopupCpmmands.showTearms: this.notifyParent.emit({command: IPopupCpmmands.showTearms}); break;
      case IPopupCpmmands.showPrivacyPolicy: this.notifyParent.emit({command: IPopupCpmmands.showPrivacyPolicy, customCommand: 'closePrivacyPolicy'}); break;
      case IPopupCpmmands.submit: this.notifyParent.emit({command: IPopupCpmmands.submit}); break;
      case IPopupCpmmands.reviewBid: this.notifyParent.emit({command: IPopupCpmmands.reviewBid}); break;
    }

  }

  incrementRenderer(params:any){

    var ui = document.createElement('div');

    ui.style['display'] = 'flex';
    ui.style['flex-direction'] = 'row';
    ui.style['align-items'] = 'center';
    ui.style['justify-content'] = 'center';
    ui.style['width'] = '100%';

    ui.innerHTML = `
      <img src="./assets/key-locker/icons/increase.png" style="width: 29px; height: 29px; margin-right: 30px;">
      <div style="font-family: 'Open Sans'; font-style: normal; font-weight: 600; font-size: 12px; line-height: 22px; letter-spacing: 0.01em; color: #F9A369">
        ${params.data.Increment}%
      </div>
    `

    return ui;

  }

  // async addCard(){

  //   try {

  //     var cardDetails = this.form.value

  //     var result = await this.extAPI.addCard({cardDetails});
  //     alert(result)
  //     this.form.reset({
  //       creditCard: [],
  //       creditCardDate: [],
  //       creditCardCvv: [],
  //       creditCardHolder: [],
  //     })
     
  //     this.cdr.detectChanges();
  //     console.log(this.form)
  //   } 

  //   catch (error) {

  //     console.log(error)
      
  //   }

    

  // }

}
