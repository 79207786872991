import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ExtapiService {

    constructor(private http: HttpClient, private appConfig: AppConfigService) { }

    getAllAuctions(): Promise<any>{
        return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/auction/viewAllAuctions', {}).toPromise();
    }

    updateCurrentRoom(requestFields:object): Promise<any>{

      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/auction/updateCurrentroom', requestFields).toPromise();
      
    }

    addCard(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/card/addCard', requestFields).toPromise();
    }

    addBid(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/bid/addBid', requestFields).toPromise();
    }

    getUserDetails(requestFields:object): Promise<any>{

      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/user/getUserDetails', requestFields).toPromise();
      
    }

    checkUserExising(requestFields:object): Promise<any>{

      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/user/checkUserExising', requestFields).toPromise();
      
    }

    addUserInSignup(requestFields:object): Promise<any>{

      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/user/addUserInSignup', requestFields).toPromise();
      
    }

    updateVisitor(requestFields: object){
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/visitors/updateVisitors', requestFields).toPromise();
    }

    updateUserprofileImage(requestFields:object): Promise<any>{

      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/user/updateUserprofileImage', requestFields).toPromise();
      
    }

    getNumbersOfUsersForRegistedAuction(requestFields:object): Promise<any>{

      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/register/getNumbersOfUsersForRegistedAuction', requestFields).toPromise();
      
    }

    CheckIfTheUserIsRegisteredForTheAuction(requestFields:object): Promise<any>{

      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/register/CheckIfTheUserIsRegisteredForTheAuction', requestFields).toPromise();
      
    }

    
    registerAuction(requestFields:object): Promise<any>{

      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/register/registerAuction', requestFields).toPromise();
      
    }

    auctionTimmerActivation(requestFields:object): Promise<any>{

      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/timmer/active', requestFields).toPromise();
      
    }
 
    getEndedTime(requestFields:object): Promise<any>{

      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/timmer/getEndedTime', requestFields).toPromise();
      
    }

    getHighestBidforAuction(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/bid/getHighestBidforAuction', requestFields).toPromise();
    }

    getNumbersOfBidsForAuction(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/bid/getNumbersOfBidsForAuction', requestFields).toPromise();
    }

    getTheLastCustomBidPerUser(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/bid/getTheLastCustomBidPerUser', requestFields).toPromise();
    }

    getTheLastBidDetails(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/bid/getLastBid', requestFields).toPromise();
    }

    setWinnerDetails(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/winners/addWinner', requestFields).toPromise();
    }

    getWinnerInfo(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/winners/getWinnerInfo', requestFields).toPromise();
    }

    getAllRegisteredAuctionsDetailsPerUser(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/register/getAllRegisteredAuctionsDetailsPerUser', requestFields).toPromise();
    }


    getUserDetailsFromId(requestFields:object): Promise<any>{

      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/user/getUserDetailsFromId', requestFields).toPromise();
      
    }


    getRegistedAuctionDetailsPerUserWithTimmerinfo(requestFields:object): Promise<any>{

      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/keylocker/getRegistedAuctionDetailsPerUserWithTimmerinfo', requestFields).toPromise();
      
    }

    getLastTenBids(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/bid/getLastTenBids', requestFields).toPromise();
    }

    getBidsByClientID(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/bid/getBidsByClientID', requestFields).toPromise();
    }

    
    getWinningBidInfo(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/winners/getWinningBidInfo', requestFields).toPromise();
    }

    getAllBidsPerUser(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/bid/getAllBidsPerUser', requestFields).toPromise();
    }

    getWinningDetailsPerUser(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/winners/getWinningDetailsPerUser', requestFields).toPromise();
    }


    getAuctionDeatailsById(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/auction/getAuctionDeatailsById', requestFields).toPromise();
    }

    getNumberOfBidsPerUser(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/bid/getAllBidsPerUser', requestFields).toPromise();
    } 
    
    getLastTenBidsInClosedAuction(requestFields:object): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('api') + '/bid/getLastTenBidsInClosedAuction', requestFields).toPromise();
    } 

    getIPAddress(): Promise<any>{  
      return this.http.get("http://api.ipify.org/?format=json").toPromise();  
    }

    createToken(payload): Promise<any>{
      return this.http.post<any>(this.appConfig.getBaseUrl('auth') + '/createToken', payload).toPromise();
    }
}
