<div class="home" >

  <div class="left-panal">
    <app-left-panal></app-left-panal>
  </div>

  <div class="content">

    <div class="top-bar">
      <app-top-bar></app-top-bar>
    </div>

    <div class="client-area">

      <app-enter-screen #enterScreen *ngIf="currentMainView === _MainViews.enterScreen"></app-enter-screen>

      <app-dashboard *ngIf="currentMainView === _MainViews.dashboard" [selectedAuctionIdx]="selectedAuctionIdx"></app-dashboard>

      <app-user-profile *ngIf="currentMainView === _MainViews.userProfile"></app-user-profile>

      <app-key-locker *ngIf="currentMainView === _MainViews.keyLocker"></app-key-locker>

      <app-message *ngIf="currentMainView === _MainViews.message"></app-message>

      <app-settings *ngIf="currentMainView === _MainViews.settings"></app-settings>



    </div>

  </div>

  <div class="right-panal">
    <app-right-panal></app-right-panal>
  </div>

  <app-settings-popup *ngIf="showSettingsDialog" [screen]="popupScreen" [caption]="showCaption" [showBackBtn]="showBackBtn" (closeDialog)="closeDialog()"></app-settings-popup>
  
  <div *ngIf="appService.allAuctions.length === 0" class="data-not-available">

    <div><img src="./assets/Spinner-1s-200px.svg"></div>
  
  </div>

</div>




