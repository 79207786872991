<div class="container">

    <div class="profile">

        <div class="pro-pic">
            <img class="pic" [src]="currentUser.profileImage">
            <!-- <img class="icon" src="./assets/profile/edit.png"> -->
        </div>

        <div class="name">
            {{ currentUser.fullName }}
        </div>

        <div class="username">
            {{ currentUser.displayName }}
        </div>

        <div class="joined">
            Join on {{ joining_date }}
        </div>

        <div class="address">
            {{ currentUser.address }}
        </div>
    </div>

    <div class="details">

        <div class="item">

            <div class="icon-area">

                <img src="./assets/profile/icons/entries.png">

                <label>
                    Entries:
                </label>

            </div>

            <div class="val">
                {{entries}}
            </div>

        </div>

        <div class="item">

            <div class="icon-area">

                <img src="./assets/profile/icons/bids.png">

                <label>
                    Bids Placed:
                </label>
                
            </div>

            <div class="val">
                {{bidsPlaced}}
            </div>

        </div>

        <div class="item">

            <div class="icon-area">

                <img src="./assets/profile/icons/coins.png">

                <label>
                    Contracts Won:
                </label>
                
            </div>

            <div class="val">
                {{ContractsWon}}
            </div>

        </div>

        <div class="item">

            <div class="icon-area">

                <img src="./assets/profile/icons/cash.png">

                <label>
                    Cash Spent:
                </label>
                
            </div>

            <div class="val">
                {{cashSpend}}
            </div>

        </div>

        <div class="item">

            <div class="icon-area">

                <img src="./assets/profile/icons/entries.png">

                <label>
                    Successful Invites
                </label>
                
            </div>

            <div class="val diff">
                2
            </div>

        </div>

    </div>

</div>
