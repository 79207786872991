import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-finish-bid',
  templateUrl: './finish-bid.component.html',
  styleUrls: ['./finish-bid.component.scss']
})
export class FinishBidComponent implements OnInit {

  
  @Input() auctionDetails;
  @Input() BiddingAmount;
  @Input() heighestBid;

  constructor() { }

  ngOnInit(): void {
  }

}
