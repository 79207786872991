import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  localApi: boolean = false;
  allAuctions = [];
  selectedAuctionIdx = 0;
  selectedAuction;
  userIP;



  constructor() { }

  //------------------- Paths -----------------------
  private paths: object = {

    'api': {
      develop: 'http://develop.backend.dreamstakes.co',
      production: 'http://35.244.150.25',
      local: this.localApi ? 'http://localhost:8080' : 'http://develop.backend.dreamstakes.co'
    },
    'auth': {
      develop: 'http://develop.auth.dreamstakes.co',
      production: 'http://34.117.158.110',
      local: this.localApi ? 'http://localhost:8081' : 'http://develop.auth.dreamstakes.co'
    }

  }
  
  public getEnvironment() {

    let url = window.location.href;

    if (url.indexOf('localhost') >= 0)
      return 'local';
    else
      if (url.indexOf('develop') >= 0)
        return 'develop'
      else
        return 'production';
        
  }

  public getBaseUrl(name: string) {
    let env = this.getEnvironment();
    return this.paths[name][env];
  }
  
}