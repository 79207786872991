<div class="container" >

  <div class="cards-area">

      <div class="a-card" *ngFor="let aCard of cards">

          <div class="icon">
              <img [src]="aCard.icon">
          </div>

          <div class="title">
              {{ aCard.title }}
          </div>

          <div class="info">

              {{ aCard.info }}

              <img *ngIf="aCard.infoIcon" [src]="aCard.infoIcon">

          </div>

      </div>

  </div>

  <div class="rooms-area" *ngIf="appService.allAuctions.length">

    <div class="room-count">

        <div class="drpdwn-area">
            <p-dropdown [options]="houses" [(ngModel)]="selectedHouse" (onChange)="setDropdown()"></p-dropdown>
        </div>

        <div class="count-area">
            Room {{selectedAuction?.current_room || '1'}} Of {{selectedAuction?.bidding_rooms}}
        </div>
        
    </div>

    <div class="progress-container" *ngIf="!enableWinnerContainer">

      <div *ngIf="isTimmerActivated">

        <ng-container *ngTemplateOutlet="conclude; context: {'selectedAuction': selectedAuction}"></ng-container>
          
      </div>

      <div class="progress-bar" *ngIf="!isTimmerActivated">

        <div class="progress-bar-title">Participatory Capacity</div>

        <p-progressBar [style]="{'border-radius': '8px'}" 
        [value]="progress" [showValue]='true'></p-progressBar>

        <ng-container *ngTemplateOutlet="room; context: {'selectedAuction': selectedAuction}"></ng-container>

      </div>

    </div>

    <div class="winner-container" *ngIf="enableWinnerContainer">

      <ng-container *ngTemplateOutlet="winnerdetails; context: {'selectedAuction': selectedAuction}"></ng-container>

    </div>

  </div>

  <div *ngIf="appService.allAuctions.length == 0" class="data-not-available">No Auctions Has Been Started Yet.</div>

  <app-custom-bid [display]="placeBid" [auctionData]="selectedAuction" (notifyParent)="recieveFromPopupDialog($event)"></app-custom-bid>

</div>

<ng-template #room let-selectedAuction="selectedAuction">

  <div class="info-area">

    <div class="details-area">

      <div class="detais-title">Attention On Auction House</div>

      <div class="bidding-area">

        <div class="left">

          <img [src]="selectedAuction?.houseImage || './assets/im-placeholder.jpg'">

        </div>

        <div class="right">

          <div class="title">
              {{selectedAuction?.title}}
          </div>

          <div class="info">

            <div class="start">

                <label class="info-title">Starting Bid</label>
                <label class="desc">${{selectedAuction?.info.startBid}}</label>

            </div>

            <div class="high">

                <label class="info-title">Highest Bid</label>
                <label class="desc">${{selectedAuction?.info.heighestBid}}</label>

            </div>

            <div class="total">

                <label class="info-title">Total Bids</label>
                <label class="desc">{{selectedAuction?.info.totalBids}}</label>

            </div>

          </div>

          <div class="buttons-area">
              
            <div class= "silde-btn-disable">
              Fast Bid
            </div>

            <div class="custom-bid-btn">
              <button  [disabled]="true">Custom Bid</button>
            </div>

          </div>

        </div>

      </div>
    
    </div>

  </div>

  <div class="grow-area">

    <div class="grow-area-title">

      <div class="grow-area-title-text"> {{selectedAuction?.biddingInfo.title}}

        <img src="./assets/dasboard/info.png">

      </div>

      <div class="grow-area-button">

        <button type="button" (click)="GrowAreaDropdownText()" style="border: none;  background-color: transparent;" *ngIf="!arrowUp">
          <img src="./assets/dasboard/icons/dropdown.png" >
        </button>

        <button type="button" (click)="GrowAreaDropdownText()" style="border: none; background-color: transparent;" *ngIf="arrowUp">
          <img src="./assets/dasboard/icons/dropdown2.png" >
        </button>
        
      </div>

    </div>
    
    <div class="grow-area-text" #droptxt>

      {{selectedAuction?.biddingInfo.desc}}

      <ol type="a">
          <li *ngFor="let item of selectedAuction?.biddingInfo.list">{{item}}</li>
      </ol>

     
    </div>

  </div>

</ng-template>

<ng-template #conclude let-selectedAuction="selectedAuction">
    
    <div class="main">

        <div class="time-area">
          
            <div class="time-area-title">Will conclude in</div>

            <div class="timer" >

              <div class="time-wrapper" id="day">

                <div  class="clock">

                  <span *ngIf="timerDays < 10"> 0 </span> {{timerDays}} </div>

                <div class="title" style="padding-left: 63px;">DAYS</div>

              </div>

              <div class="time-wrapper" id="hours">

                <div  class="clock">

                  <span *ngIf="timerHours < 10"> 0 </span> {{timerHours}} </div>

                <div class="title" style="padding-left: 54px;">HOURS</div>

              </div>


              <div class="time-wrapper" id="minutes">

                <div  class="clock">

                  <span *ngIf="timerMin < 10" > 0  </span> {{timerMin}}</div>

                <div class="title" style="padding-left: 39px;">MINUTES</div>

              </div>


              <div class="time-wrapper" id="seconds">

                <div  class="clock">

                  <span *ngIf="timerSec < 10"> 0 </span> {{timerSec}}</div>
                 
                <div class="title" style="padding-left: 35px;">SECONDS</div>

              </div>

            </div>

        </div>

        <div class="bidding-area">

          <div class="left">
  
              <img [src]="selectedAuction?.houseImage">
  
          </div>
  
          <div class="right">
  
              <div class="title">
                  {{selectedAuction?.title}}
              </div>
  
              <div class="info">
  
                  <div class="start">
  
                      <label class="info-title">Starting Bid</label>
                      <label class="desc">${{selectedAuction?.info.startBid}}</label>
  
                  </div>
  
                  <div class="high">
  
                      <label class="info-title">Highest Bid</label>
                      <label class="desc">${{selectedAuction?.info.heighestBid}}</label>
  
                  </div>
  
                  <div class="total">
  
                      <label class="info-title">Total Bids</label>
                      <label class="desc">{{selectedAuction?.info.totalBids}}</label>
  
                  </div>
  
              </div>
  
              <div class="buttons-area">
  
                <div *ngIf="enableFastBidding">
  
                  <div class= "silde-btn"  id="silde-btn" #drop *ngIf="!disableFastBitBtn">
  
                    <div
                      #dragbtn
                      id="dragbtn"
                      class="dragbtn"
                      cdkDragBoundary=".silde-btn"
                      cdkDragLockAxis="x"
                      cdkDrag 
                      (cdkDragEnded)="dragEnd()"
                      [cdkDragFreeDragPosition]="dragPosition">
                      
                      <div class="img-background">
                        <img src="./assets/dasboard/icons/slider-arrow-btn.png"/>
                      </div>
  
                    </div>
  
                    <div class="text">
                      
                      <div >
                        <!-- <div>Release</div> -->
                        <div>Silde to fast bid</div>
                      </div>
                      
                      <!-- <div *ngIf="disableFastBitBtn">Confirmed!</div> -->
  
                    </div>
                      
                  </div>
  
                  <div class= "silde-btn" *ngIf="disableFastBitBtn">
                    <img src="./assets/dasboard/comfiremd.png" >
                  </div>
  
                </div>
                  
  
                <div class= "silde-btn-disable" *ngIf="!enableFastBidding">
                  Fast Bid
                </div>
  
                <div class="custom-bid-btn">
                  <button (click)="showPlaceBid()" [disabled]="disableCustomBidBtn">Custom Bid</button>
                </div>
  
              </div>
  
          </div>
  
      </div>
  
      <div class="bidding-info">
  
          <div class="info-title">
  
              {{selectedAuction?.biddingInfo.title}}
  
              <img src="./assets/dasboard/info.png">
  
          </div>
  
          <div class="desc" [innerHTML]="selectedAuction?.biddingInfo.desc">
  
              <!-- <ol type="a">
                  <li *ngFor="let item of selectedAuction.biddingInfo.list">{{item}}</li>
              </ol> -->
  
          </div>
  
      </div>

    </div>
    
    
</ng-template>


<ng-template #winnerdetails let-selectedAuction="selectedAuction">

  <div class="winner-content">

    <div>The winner is</div>
    <div class="winner"> {{winner}} </div>
    
  </div>

</ng-template>
