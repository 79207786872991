<div class="container popup-cotroller" [style.width.px]="width ? width : 'initial'" [style.height.px]=" height ? height : 'initial'">

    <p-dialog [(visible)]="display" position="top" [draggable]="false" (onHide)="close(popupData?.customContents)" [transitionOptions]="'200ms'">

        <p-header>
            {{popupData?.header}}
        </p-header>

        <div class="content-header">
            {{popupData?.contentHeader}}
        </div>

        <div *ngIf="!popupData?.customContents" [innerHTML]="popupData?.content | safeHtml"></div>

        <div *ngIf="popupData?.customContents == 'agreement'">

            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tempus et lacus at maximus. Vivamus dignissim tincidunt turpis, eu lacinia justo tempor eu. Morbi aliquam nulla non porta tincidunt. Vivamus at ultricies enim, eget tempus quam. Etiam nisi felis, ultricies ut eros nec, accumsan luctus lectus. Donec justo enim, venenatis ac tellus quis, congue ultrices arcu. In feugiat nec dolor non vehicula. Proin id tincidunt erat.<br><br>

            Nullam consectetur interdum mollis. Morbi in lacus sapien. Vivamus aliquet massa sed lacus pretium lacinia. Maecenas quis leo sed tortor maximus eleifend. Sed sit amet facilisis neque. Proin tempus massa sit amet lorem sagittis, at ultricies quam auctor. Suspendisse quis nulla commodo, elementum felis vel, pellentesque justo. In mi sem, fringilla at fermentum at, vulputate suscipit sapien. Vivamus at tincidunt libero, id rutrum nulla. Curabitur auctor lectus sit amet ex imperdiet consequat. Donec tempor magna sapien, tempus ultricies neque ultricies sed.<br><br>
      
          <ul>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
            <li>Proin auctor ex quis sollicitudin semper.</li>
            <li>Aliquam semper enim nec laoreet mattis.</li>
            <li>Vivamus interdum elit vitae condimentum pretium.</li>
            <li>Nullam vel ligula semper, viverra nulla in, pulvinar turpis.</li>
            <li>Curabitur non elit congue, rutrum massa ut, tempor orci.</li>
          </ul>

          <br>
          <br>
          <br>

          <p-checkbox [(ngModel)]="checked1" [binary]="true"  name="groupname" label="I agree with the"></p-checkbox><label style="margin-left: 3px; cursor: pointer; font-family: 'Open Sans'; font-style: normal; font-weight: 600; font-size: 15px; line-height: 24px; text-decoration-line: underline; color: #F9A369;" (click)="sendCommandToParent('showTearms')">Terms and Conditions</label>
          <br>
          <br>
          <p-checkbox [(ngModel)]="checked2"  [binary]="true" name="groupname" label="I agree with For"></p-checkbox><label style="position: relative; top: 2px; left:5px; cursor: pointer; font-family: 'Open Sans'; font-style: normal; font-weight: 600; font-size: 15px; line-height: 24px; text-decoration-line: underline; color: #F9A369;" (click)="sendCommandToParent('showPrivacyPolicy')">Privacy Policy</label>

        </div>

        <div *ngIf="popupData?.customContents == 'payments'">

            <p-radioButton name="groupname" value="cc" label="Credit & Debit Cards" [(ngModel)]="paymenttype"></p-radioButton>
            <br>
            <br>

            <div *ngIf="paymenttype == 'cc'" style="width: 410px;">

                <form class="cc-form" [formGroup]="form">

                    <div class="input-wrapper">

                        <span class="button-label">Card number</span>

                        <div class="ui-inputgroup">

                            <div class="form-group">

                                <ngx-cc defaultStyles placeholder="0000 0000 0000 0000" required="true" styleClass="default-input"
                                    name="cardNumber" formControlName="creditCard">
                                </ngx-cc>

                                <div class="card-err1"*ngIf="form.controls.creditCard.invalid && form.controls.creditCard.touched" >Card number is not valid</div>
                                

                            </div>

                        </div>

                    </div>


                    <div style="display: flex; justify-content: space-between;">

                        <div class="input-wrapper" style="width: 200px;">

                            <span class="button-label">Expiry date</span>
        
                            <div class="ui-inputgroup">

                                <div class="form-group">

                                    <ngx-cc-date defaultStyles styleClass="default-input" placeholder="MM / YY"
                                        formControlName="creditCardDate">
                                    </ngx-cc-date>

                                    <div class="card-err" *ngIf="form.controls.creditCardDate.invalid && form.controls.creditCardDate.touched">Expiration date is not valid</div>
                
                                </div>
        
                                
                                <span class="ui-inputgroup-addon">
                                    <img src="./assets/popup-btns/calender.png" style="width: 18px; height: 19px; position: relative; top: 4px;">
                                </span> 
        
                            </div>
        
                        </div>
        
                        <div class="input-wrapper" style="width: 200px;">
        
                            <span class="button-label">CVC/CVV</span>
        
                            <div  class="ui-inputgroup">

                                <div class="form-group">
                                    <ngx-cc-cvv defaultStyles styleClass="default-input" placeholder="CVV"
                                        formControlName="creditCardCvv"></ngx-cc-cvv>

                                        <div class="card-err" *ngIf="form.controls.creditCardCvv.invalid && form.controls.creditCardCvv.touched">Security code is not valid</div>
                                   
                                </div>
                                
                                <span class="ui-inputgroup-addon">
                                    <img src="./assets/popup-btns/info.png" style="width: 16px; height: 16px; position: relative; top: 4px;">
                                </span> 
                            </div>
                
                        </div>

                    </div>


                    <div class="input-wrapper">

                        <span class="button-label">Cardholder name</span>

                        <div class="ui-inputgroup" style="margin-bottom: 22px;">
                            
                            <div class="form-group">

                                <input type="text" pInputText placeholder="Enter cardholder’s full name" style="width: 100%;" class="common-txt" formControlName="creditCardHolder">
                                
                            </div>

                            <span class="ui-inputgroup-addon" style="padding-top: 22px;">
                                <img src="./assets/popup-btns/cc.png" style="width: 22px; height: 15px;">
                            </span> 

                        </div>

                    </div>


                    <div>
                        <p-radioButton name="groupname" value="val2" label="Save card"></p-radioButton>
                    </div>

                    <div style="margin-top: 25px; margin-bottom: 65px;">
                        <!-- <p-button label="Add card" class="p-button-warning" (onClick)="addCard()"></p-button> -->
                        <button type="submit"  [disabled]="form.invalid" style="
                            width: 374px;
                            height: 52px;
                            background: #F9A369;
                            border-radius: 10px;
                            border: none;
                            outline: none;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 17px;
                            line-height: 26px;
                            color: #FCFCFC;"
                            *ngIf="popupData?.customFooterButton?.includes('submit')"
                            (click)="addCard()"
                        >
                            Add card
                        </button>
                    </div> 

                </form>
                  
            </div>

            <p-radioButton name="groupname" value="paypal" label="Paypal" [(ngModel)]="paymenttype"></p-radioButton>

            <div *ngIf="paymenttype == 'paypal'" style="width: 475px;">

                <div class="input-wrapper">

                    <span class="button-label">Email address</span>

                    <div class="ui-inputgroup">
                        <input type="text" pInputText placeholder="Enter your paypal email address" class="common-txt">   
                        <span class="ui-inputgroup-addon" style="padding-top: 13px;">
                            <img src="./assets/popup-btns/cc.png" style="width: 22px; height: 15px;">
                        </span>  
                    </div>

                </div>


                <div>
                    <p-radioButton name="groupname" value="val2" label="Save paypal"></p-radioButton>
                </div>

                <div style="margin-top: 45px;">
                    <p-button label="Add paypal" class="p-button-warning"></p-button>
                </div>

            </div>

        </div>

        <p-footer *ngIf="popupData?.showFooter">

            <div>

                <img [style.visibility]="popupData?.bckButton ? 'visible' : 'hidden'" src="./assets/popup-btns/back-arrow.png" (click)="sendCommandToParent('back')">

                <div style="display: flex; justify-content: flex-end; width: 100%;">
                    <!-- <img  class="submit-btn" *ngIf="popupData?.customFooterButton?.includes('submit')" src="./assets/popup-btns/submit-btn.png" (click)="sendCommandToParent('submit')"> -->
                    <button [disabled]="dataNotAvailable" style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 235px;
                        height: 52px;
                        background:  #F9A369 ;  
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 26px;
                        color: #FCFCFC;
                        cursor: pointer;
                        border: none;
                        border-radius: 10px;
                        margin-top: 25px;"
                        *ngIf="popupData?.customFooterButton?.includes('submit')"
                        (click)="sendCommandToParent('submit')"
                    >
                        Submit and Process
                    </button>

                </div>
                

                <div *ngIf="popupData?.nxtButton" style="display: flex; justify-content: flex-end;">

                    <img  src="./assets/popup-btns/next-arrao.png" *ngIf="popupData?.nxtButton && (!popupData?.nxtButtonDis || isChecked)"   (click)="sendCommandToParent('next')" >

                    <img src="./assets/popup-btns/icons8_down_arrow 1.png" *ngIf="popupData?.nxtButtonDis && !isChecked " class="arrow">
                    
                    <img src="./assets/popup-btns/next-arrow-disable.png" *ngIf="popupData?.nxtButtonDis && !isChecked " >

                    

                </div>


                <img *ngIf="popupData?.customFooterButton?.includes('finish')" style="width: 170px; height: 52px;" src="./assets/dasboard/finish.png" (click)="sendCommandToParent('submit')">

            </div>

        </p-footer>

    </p-dialog>

</div>
