import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, NgZone } from '@angular/core';
// import { Router } from '@angular/router';
// import { SocialAuthService, SocialAuthServiceConfig} from 'angularx-social-login';
// import { FacebookLoginProvider } from 'angularx-social-login';
import { AuthService } from '../auth.service';
import { IUser } from '../ds-components/ds-types';
import { ProfileService } from '../profile.service';
import { ExtapiService } from '../extapi.service';
import { Router } from '@angular/router';

declare var google: any;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, AfterViewInit {

    @ViewChild('pwd') pwd: ElementRef;
    @ViewChild('email') email: ElementRef;
    @ViewChild('icon') icon: ElementRef;

    year;
    userEmail: string ='test@gmail.com';
    userPassword : string ='123456';

    // user: SocialUser;
    loggedIn: boolean;

    // googleLoginOptions = {
    //     scope: 'profile email'
    // }



  
    constructor(

        public authService: AuthService,
        public router: Router,
        public profileservice: ProfileService,
        public extapiService: ExtapiService
        // private zone: NgZone,
        // private socialAuthService: SocialAuthService

    ) { }

    ngOnInit(): void {

        this.year = new Date().getFullYear();

        // this.user.email = "example@gmail.com";
        // // this.user.password =this.userPassword;

        // this.socialAuthService.authState.subscribe((user) => {
        //     this.user = user;
        //     this.loggedIn = (user != null);

        //   console.log(this.user.email)
        // });
        
    }

    ngAfterViewInit(){

        // google.accounts.id.initialize({
        //     client_id: "42499149520-0f60ebohbh0tb3cp0gc4ss673b8f5spr.apps.googleusercontent.com",
        //     callback: (response: any) => this.handleGoogleSignIn(response)
        // });

        // google.accounts.id.renderButton(
        //     document.getElementById("google-btn"),
        //     { size: "large", type: "icon", shape: "pill" }  // customization attributes
        // );

    }

    // handleGoogleSignIn(response: any) {
    
    //     // This next is for decoding the idToken to an object if you want to see the details.
    //     let base64Url = response.credential.split('.')[1];
    //     let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    //     let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    //       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    //     }).join(''));

    //     let userData = JSON.parse(jsonPayload);

    //     let currentUser: IUser = {
    //         displayName: userData.given_name,
    //         email: userData.email,
    //         fullName: userData.name,
    //         profileImage: userData.picture
    //     }

    //     this.profileservice.setUser(currentUser);

    //     this.authService.isValidaded = true;

    //     this.zone.run(() => {
    //         this.router.navigate(['']);
    //     });
        
        
    // }
    
    // loginWithFacebook(): void {
    //     this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => console.log(x));
    // }
    

    async login(){

        this.authService.login(this.userEmail, this.userPassword).then(async () =>{     
      
            this.router.navigate(['/home']);
      
        })
        
        // const email = this.email.nativeElement.value;
        // const password = this.pwd.nativeElement.value;


        // try {

        //     let result =  await this.extapiService.getUserDetails({email:email});

        //     if(result.length !== 0){

        //         let currentUser: IUser = {
        //             displayName: result[0].username,
        //             email: email,
        //             fullName: result[0].full_name,
        //             profileImage: result[0].profile_image,
        //             client_id:result[0].client_id,
        //             address: result[0].address,
        //             date_of_birth: result[0].date_of_birth,
        //             phone_number: result[0].phone_number,
        //             date: result[0].date,
        //             password:result[0].password
        //         }
            
        //         this.profileservice.setUser(currentUser);
            
        //         localStorage.setItem("currentUser",JSON.stringify(currentUser));

        //         this.router.navigate(["home"])



        //     }

        //     else{

        //         alert("Please sing up");
        //         this.router.navigate(["signup"]);

        //     }
            
        // } 
        
        // catch (error) {

        //     console.log(error);
        //     alert("Something went wrong");
            
        // }

        


        // if ( this.userEmail == email && this.userPassword == password ){

        //     let currentUser: IUser = {
        //         displayName: "Developer",
        //         email: this.userEmail,
        //         fullName: "Developer",
        //         profileImage: './assets/profile/pro-pic.png',
        //         client_id:1234
        //     }

        //     this.profileservice.setUser(currentUser);

        //     this.authService.isValidaded = true;

        // }

        // else{

        //     alert("error");

        // }

    }

    showPassword(){

        const password = this.pwd.nativeElement;
        const eyeIcon = this.icon.nativeElement;

        const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
        password.setAttribute('type', type);

        eyeIcon.classList.toggle("pi-eye");
            
    }



}
