<div class="signin-container">

    <div class="logo">
        <img src="./assets/LOGO@3xDecision 2 1.png">
    </div>

    <div class="content">

        <div class="title">Sign in for free to start Bid 
            on a Dream Home.</div>

        <!-- <div class="icon-area">

            <div class="icon"> -->

                <!-- <div><img src="./assets/login/Facebook.png" (click)="loginWithFacebook()" style="cursor: pointer;"></div> -->
                <!-- <div id="fb-root">
                    <div class="fb-login-button" data-width="" data-size="" data-button-type="" data-layout="" data-auto-logout-link="false" data-use-continue-as="false"></div>
                </div>
                <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v16.0&appId=6183749555025381&autoLogAppEvents=1" nonce="NBjLGVnm"></script> -->
               
                <!-- <div><img src="./assets/login/apple.png"></div> -->

                <!-- <div class="google">

                    <div id="google-btn"></div>
                    
                </div>  -->

            <!-- </div>

            <div class="text">or sign in with your email</div>

        </div> -->


        <div class="email">

            <div class="email-title">Email Address</div>

            <input type="text" #email pInputText [(ngModel)]= "userEmail" name="userEmail" />
            <!-- <input type="text" #email pInputText  /> -->
            
        </div>

        <div class="password">

            <div class="password-title">

                <div>Password</div>

                <div>
                    <a>Forgot Password?</a>
                </div>

            </div>

            <div class="ui-inputgroup">

                <input type="password" #pwd pInputText [(ngModel)]= "userPassword" name="userPassword" />
                <!-- <input type="password" #pwd pInputText  /> -->
                
                <span class="ui-inputgroup-addon"><i class="pi pi-eye-slash" (click)="showPassword()" #icon></i></span>  
     
            </div>

            
            
        </div>


        <div class="button-area">
            <button type="button"  [routerLink]="''" (click)="login()">Sign In</button>
        </div>

        <div class="sign-up">

            <div>New to App?</div> 

            <div>

                <a [routerLink]="'/signup'">Sign Up</a>

            </div>

        </div>

    </div>

    <div class="footer">

        <div class="left">

            <div class="water-mark">© 2023 dreamstakes com</div>

            <div><a> Contact Us</a></div> 
            
        </div>
        
        <div class="right">
            <div>
                <a>Terms & Conditions</a>
            </div>
            <div>
                <a>Privacy Policy</a>
            </div>
        </div>

    </div>

    
</div>
