import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPopupConfigs, IPopupCpmmands } from '../../ds-types';
import { FormBuilder,FormGroup } from '@angular/forms';
import { ExtapiService } from 'src/app/extapi.service';


@Component({
  selector: 'app-ds-contract',
  templateUrl: './ds-contract.component.html',
  styleUrls: ['./ds-contract.component.scss']
})
export class DsContractComponent implements OnInit {

  @Input() display: boolean = false;
  @Input() popupData: IPopupConfigs;
  @Input() width: number;
  @Input() height: number;

  @Output() notifyParent = new EventEmitter<any>();
  paymenttype: string = '';

  form;

  checked1: boolean = false;
  checked2: boolean = false;
  isChecked: boolean = false;

  dataNotAvailable: boolean = true;

  constructor(
    private fb: FormBuilder,
    private extAPI: ExtapiService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

   this.form = this.fb.group({
        creditCard: [],
        creditCardDate: [],
        creditCardCvv: [],
        creditCardHolder: [],
    });

  }

  close(customClose){
    this.sendCommandToParent(IPopupCpmmands.close, customClose);
  }

  sendCommandToParent(command, customCommand = false){
    
    switch(command){
      case IPopupCpmmands.close: this.notifyParent.emit({command: IPopupCpmmands.close, customCommand: 'closeTearms'}); break;
      case IPopupCpmmands.next: this.notifyParent.emit({command: IPopupCpmmands.next}); break;
      case IPopupCpmmands.back: this.notifyParent.emit({command: IPopupCpmmands.back}); break;
      case IPopupCpmmands.showTearms: this.notifyParent.emit({command: IPopupCpmmands.showTearms}); break;
      case IPopupCpmmands.showPrivacyPolicy: this.notifyParent.emit({command: IPopupCpmmands.showPrivacyPolicy, customCommand: 'closePrivacyPolicy'}); break;
      case IPopupCpmmands.submit:
        this.notifyParent.emit({command: IPopupCpmmands.submit}); 
      break;
      case IPopupCpmmands.reviewBid: this.notifyParent.emit({command: IPopupCpmmands.reviewBid}); break;
    }

  }

  async addCard(){

    try {

      var cardDetails = this.form.value;

      localStorage.setItem("ds-debit", JSON.stringify(cardDetails));

      this.dataNotAvailable = false;

      alert("successful");

      // var result = await this.extAPI.addCard({cardDetails});
      // alert(result)

    } 

    catch (error) {

      console.log(error)
      
    }

    

  }

  change() {

    var checkBox1 = this.checked1 ? "Checked" : "Unchecked"
    var checkBox2 = this.checked2 ? "Checked" : "Unchecked"

    if(checkBox1 && checkBox2 === "Checked"){

      this.isChecked = true;

    }

    else{

      this.isChecked = false;

    }
    
  }


}
