<div class="container">

    <div class="section-1">
        <div class="ui-inputgroup">
            <span class="ui-inputgroup-addon"><i class="pi pi-search"></i></span>
            <input type="text" pInputText placeholder="Search for frequently asked questions">         
        </div>
    </div>

    <div class="section-2"></div>

    <div class="section-3">

        <div class="profile" (click)="userChangeView(_MainViews.userProfile, true, false)">
            <label>{{currentUser.displayName}}</label>
            <img [src]="currentUser.profileImage">
        </div>

        <div class="notifications">
            <button type="button" (click)="showNotification()">
                <img src="./assets/notify-icon.png">
            </button>
           
        </div>

        
    </div>

    <div class="popup" #notificationPopup>

        <p-tabView (onChange)="onChange($event)" [activeIndex]="selectedIndex" #TabView>

            <p-tabPanel [selected]="true">

                <ng-template pTemplate="header"  >

                    <div class="notification-icon">
                        <img src="./assets/notification-icon-light.png" *ngIf = "!selectedIndex">
                        <img src="./assets/notification-icon-dark.png" *ngIf = "selectedIndex">
                    </div>

                    <div class="header">Notifications (0)</div>

                </ng-template>

                <div class="notifications-container">

                    <app-notification></app-notification>
                    
                </div>
                
            </p-tabPanel>

            <p-tabPanel>

                <ng-template pTemplate="header">

                    <div class="inbox-icon">
                        <img src="./assets/inbox-icon-dark.png"  *ngIf = "!selectedIndex ">
                        <img src="./assets/inbox-icon-light.png" *ngIf = "selectedIndex  ">
                    </div>

                    <div class="header">Indox (0)</div>

                    </ng-template>

                    <div class="inbox-container">

                        <app-inbox></app-inbox>

                    </div>

            </p-tabPanel>

        </p-tabView>

    </div>

</div>
