import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ProfileService } from './profile.service';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {

    constructor(

        public authService: AuthService,
        public router: Router,
        private profile: ProfileService

    ){}

    canActivate(): boolean {
    
        if (!this.authService.isAuthenticated()) {

            this.router.navigate(['']);
            return false;

        }
        
        else{
        
            let currUser = JSON.parse(localStorage.getItem('currentUser'));
            this.profile.setUser(currUser);

            return true;
        }
      
    }

}
