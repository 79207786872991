import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { IPopupConfigs, IPopupCpmmands, biddingType } from '../../ds-types';
import { ProfileService } from 'src/app/profile.service';
import { ExtapiService } from 'src/app/extapi.service';
import * as moment from 'moment';

@Component({
  selector: 'app-custom-bid',
  templateUrl: './custom-bid.component.html',
  styleUrls: ['./custom-bid.component.scss']
})
export class CustomBidComponent implements OnInit {
  
  @Input() display: boolean = false;
  @Input() auctionData;
  @Output() notifyParent = new EventEmitter<any>();
  biddingAmount;
  lastBiddingAmount = 0;
  authenticationFee: any = "Free";
  total;
  highestBidForCustom = 500;
  disableBidBtn : boolean = true ;
  disableReviewBidBtn : boolean = true ;
  openFinishBid:boolean = false;

  heighestBidArr;
  totalBiddingAmount;

  constructor(
    public profileService: ProfileService,
    public extapiService: ExtapiService,
  ) { }

  ngOnInit(){}

  sendCommandToParent(command, customCommand = false){
    
    this.openFinishBid = true;

    switch(command){

      case IPopupCpmmands.close: 
        this.notifyParent.emit({command: IPopupCpmmands.close, customCommand: 'closeTearms'});
        this.lastBiddingAmount = 0;
        this.total = 0;
        this.openFinishBid = false;
        this.disableBidBtn  = true ;
        this.disableReviewBidBtn  = true ; 
        break;

      case IPopupCpmmands.reviewBid: this.notifyParent.emit({command: IPopupCpmmands.reviewBid}); break;
    }

  }

  async bid(){

    if(this.highestBidForCustom >= this.biddingAmount && this.biddingAmount >= 50 ){

      this.lastBiddingAmount = this.biddingAmount;

      // check whether the authentication fee is free or has value
      if(this.authenticationFee === "Free"){
  
        this.total = this.lastBiddingAmount;

      }

      else{
  
        this.total = Number(this.lastBiddingAmount) + Number(this.authenticationFee);
  
      }

      try {

        let clent_id = this.profileService.currentUser.client_id;
        let auction_id = this.auctionData.auction_id;
        
        let heighestBid = this.auctionData.info.heighestBid;
       
         //Get the highest bid in the current auction from database
        this.heighestBidArr = await this.extapiService.getHighestBidforAuction({auctionId: this.auctionData.auction_id, current_room: this.auctionData.current_room});
        let actualHeighestBid = this.heighestBidArr[0].heighestBid;

        //For first bid
        if(actualHeighestBid === null){ 

          actualHeighestBid = 0;

        }

        //If the highest bid value has changed during the current period
        if(heighestBid !== actualHeighestBid){

          this.totalBiddingAmount = this.lastBiddingAmount + actualHeighestBid;
          alert("The highest bidding amount has changed to $"+ actualHeighestBid+". so your bid will increase to $"+this.totalBiddingAmount)

        }

        else{

          this.totalBiddingAmount = this.lastBiddingAmount + heighestBid;

        }

        let date = moment().format();

        var result = await this.extapiService.addBid({client_id : clent_id, 
          auction_id :auction_id,
          bid : this.lastBiddingAmount,
          biddingType: biddingType.custom,
          date : date,
          current_room: this.auctionData.current_room
        }); 

        this.biddingAmount = '';
        this.disableReviewBidBtn = false;
  
        
      } 


      catch (error) {

        alert("error");
        console.log(error)
        
      }

    }

    else{

      alert("You can only bid from $50 to $500");
      this.disableBidBtn = true;
      this.biddingAmount = '';

    }

   
  }

  bidButtonEnable(){

    this.disableBidBtn = false;


  }

}
