<div class="signup-container">

    <div class="logo">
        <img src="./assets/LOGO@3xDecision 2 1.png">
    </div>

    <div class="content">

        <div class="title">Sign up</div>

        <div class="sub-text">If you already have an account register you can

            <div [routerLink]="''">
                <a> Login here !</a>
            </div>
            
        </div>

        <div class="username">

            <div class="username-title">Username</div>

            <input type="text" pInputText [(ngModel)]= "username"/>
            
        </div>

        <div class="name">

            <div>

                <div style="margin-bottom: 8px;"> Name</div>

                <input type="text" pInputText [(ngModel)]= "firstName"/>

            </div>

            <div>

                <div style="margin-bottom: 8px;">Surname</div>

                <input type="text" pInputText [(ngModel)]= "lastName" />
                
            </div>
            

        </div>

        <div class="email">

            <div class="email-title">Email Address</div>

            <input type="email"  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" pInputText [(ngModel)]= "email"/>
            
        </div>

        <div class="password">

            <div class="password-title">

                <div>Password</div>

                <div>
                    <a>Forgot Password?</a>
                </div>

            </div>

            <div class="ui-inputgroup">

                <input type="password" #pwd pInputText [(ngModel)]= "password"/>
                
                <span class="ui-inputgroup-addon"><i class="pi pi-eye-slash" (click)="showPassword()" #icon></i></span>  
     
            </div>
            
        </div>

        <div class="check-box">
            <p-checkbox name="groupname" value="val1" label="By Signing Up I agree with "></p-checkbox>
            <label>Terms and Conditions</label>
        </div>

        <div class="button-area">

            <button type="button" (click)="SignUp()">Sign Up</button>
            
        </div>

        <div class="sign-in">

            <div>Already have an Account?</div> 

            <div>

                <a [routerLink]="''">Sign In</a>

            </div>

        </div>

    </div>

    <div class="footer">

        <div class="left">

            <div class="water-mark">© {{year}} dreamstakes com</div>

            <div><a> Contact Us</a></div> 
            
        </div>
        
        <div class="right">
            <div>
                <a>Terms & Conditions</a>
            </div>
            <div>
                <a>Privacy Policy</a>
            </div>
        </div>

    </div>

</div>
