<div class="container popup-cotroller" [style.width.px]="width ? width : 'initial'" [style.height.px]=" height ? height : 'initial'">

    <p-dialog [(visible)]="display" position="top" [draggable]="false" (onHide)="close()" [transitionOptions]="'200ms'">

        <p-header>
            DreamStakes Rules & Regulation
        </p-header>

        <div class="content-header">
            DreamStakes Contract 
        </div>

        <!-- <div [innerHTML]="popupData?.content | safeHtml"></div> -->

        <iframe *ngIf="URIBlob" [src]="URIBlob" frameborder="0" height="100%" width="100%"></iframe> 
        <!-- <div class="editor" style="display: flex; width: 100%;">
            <p-editor [(ngModel)]="text2" [innerHTML]="popupData?.content | safeHtml"  [style]="{'height':'252px', 'border':'none'}" readonly="true">
                <p-header style="border: none;">
                  <span class="ql-format-group" style="display: none; border: none;"></span>
                </p-header>
            </p-editor>
        </div> -->

        <p-checkbox [(ngModel)]="agreeToTermsCondition" value="val1" [binary]="true"  name="groupname" label="I agree with the"></p-checkbox><label class="terms-condition">Terms and Conditions</label>
        <br>
        <br>
        <p-checkbox [(ngModel)]="agreeToPrivacyPolicy" value="val2"  [binary]="true" name="groupname" label="I agree with For"></p-checkbox><label class="privacy-policy">Privacy Policy</label>

        <p-footer>

            <div style="display: flex; justify-content: flex-start; width: 100%; margin-top: 25px;">

                <button [disabled]="!agreeToTermsCondition && !agreeToPrivacyPolicy" (click)="checkForPaymentsAndProcess()">Done</button>

            </div>

        </p-footer>

    </p-dialog>

</div>

<app-add-card-popup [display]="showAddCardPopup" (closePopup)="closeCardPopup($event)"></app-add-card-popup>