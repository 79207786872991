<div class="message-container">

    <div class="title-area">

        <div>
            <img src="./assets/inbox-image.png">
        </div>

        <div>

            <div class="name">Admin</div>

            <div class="status">

                <img src="./assets/message/Online_status.png" style="height: 16px; width: 16px; margin-right: 0px;">
                
                Online
                
            </div>

        </div>

    </div>

    <div class="message-content">

        <div class="message-box">

            <div class="messages" #one></div>
            
        </div>
        
        

        <div class="input-area">

            <input (keyup.enter)="sendMessage()" id="m"  [(ngModel)]="text" placeholder="Type a messages…" autocomplete="off" required />

            <div class="image-priview-area" *ngIf="uplodedFileContents.length">

                <div class="image-priview-container" *ngFor="let imageContent of uplodedFileContents; let i = index;" >

                    <div class="image-area">
                        
                        <img [src]="imageContent.src"/>

                        <i class="pi pi-times-circle" (click)="removeImage(i)"></i>

                    </div>

                    <div class="file-name">{{imageContent.name}}</div>

                </div>

            </div>

            <div class="btn-area">
            
                <div class="upload">

                    <div class="icon1">

                        <label for="img"><img src="./assets/message/Add2.png"></label>
                        <input (change)="uploadFile($event)" style="height: 0px;" type="file" id="img" name="img">

                    </div>

                </div>


                <div class="send">

                    <button (click)="sendMessage()">
                
                        <img src="./assets/message/Icon _send.png" alt="">

                    </button>

                </div>

            </div>


        </div>

    </div>



</div>