import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ExtapiService } from '../extapi.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ProfileService } from '../profile.service';
import { IUser } from '../ds-components/ds-types';
import { AppConfigService } from '../app-config.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignUpComponent implements OnInit {


    @ViewChild('pwd') pwd: ElementRef;
    @ViewChild('icon') icon: ElementRef;

    year;

    username;
    firstName;
    lastName;
    email;
    password;


    constructor(
        public extapiService: ExtapiService,
        public authService: AuthService,
        public router: Router,
        public profileservice: ProfileService,
        private appService: AppConfigService
    ) { }

    ngOnInit(): void {

        this.year =  new Date().getFullYear();
    }


    showPassword(){

        const password = this.pwd.nativeElement;
        const eyeIcon = this.icon.nativeElement;

        const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
        password.setAttribute('type', type);

        eyeIcon.classList.toggle("pi-eye");
            
    }

    async SignUp(){

        try {

            await this.extapiService.checkUserExising({username:this.username});

            alert("User already exist. Please signin")
            
        } 
        
        catch (error) {

            try {

                var date =new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000).toJSON().slice(0, 19).replace('T', ' ');

                let user = {

                    username:this.username,
                    firstName:this.firstName,
                    lastName:this.lastName,
                    fullName:this.firstName +" "+this.lastName,
                    email:this.email,
                    password:this.password,
                    date: date,
                    role: 2 // indicates regular user
          
                }

                await this.extapiService.addUserInSignup({user});

                let result =  await this.extapiService.getUserDetails({email:this.email});
    
                let currentUser: IUser = {
                    displayName: result[0].username,
                    email: result[0].email,
                    fullName: result[0].full_name,
                    profileImage: result[0].profile_image,
                    client_id:result[0].client_id,
                    address: result[0].address,
                    date_of_birth: result[0].date_of_birth,
                    phone_number: result[0].phone_number,
                    date: result[0].date,
                    password:result[0].password
                }

                // update visitors
                await this.extapiService.updateVisitor({ip_address: this.appService.userIP, client_id: result[0].client_id})
            
                this.profileservice.setUser(currentUser);
            
                localStorage.setItem("currentUser",JSON.stringify(currentUser));

                this.username = "";
                this.firstName  = "";
                this.lastName  = "";
                this.email  = "";
                this.password  = "";
                
                alert("Successfully");

                this.router.navigate(["home"]); 

            } 
            
            catch (error) {

                console.log(error);
                alert("Fill all fields");
                
            }
            
        }

    }

}
