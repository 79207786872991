import { Injectable, Injector } from '@angular/core';
import { SignInComponent } from './sign-in/sign-in.component';
import { ProfileService } from './profile.service';
import { IUser } from './ds-components/ds-types';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ExtapiService } from './extapi.service';

let InjectorInstance: Injector;

@Injectable({
    
    providedIn: 'root'
})

export class AuthService {

    isValidaded : boolean = false;
    user: IUser

    constructor(
        public profileService: ProfileService,
        public jwtHelper: JwtHelperService,
        private injector: Injector,
        private extapi: ExtapiService
    ) {

        InjectorInstance = this.injector;

    }

    login(email, password) {

        return new Promise(async (resolve, reject) => {
    
            try{
    
                let data = await this.extapi.createToken({email, password});
        
                if(data.success){
                    
                    localStorage.setItem("ds-token", data.token);
                    
                    let result =  await this.extapi.getUserDetails({email:email});

                    let currentUser: IUser = {
                        displayName: result[0].username,
                        email: email,
                        fullName: result[0].full_name,
                        profileImage: result[0].profile_image,
                        client_id:result[0].client_id,
                        address: result[0].address,
                        date_of_birth: result[0].date_of_birth,
                        phone_number: result[0].phone_number,
                        date: result[0].date,
                        password:result[0].password
                    }
                
                    this.profileService.setUser(currentUser);
                
                    localStorage.setItem("currentUser",JSON.stringify(currentUser));

                    resolve(true);
                }
                else{
                    alert("Login failed");
                    reject(false);
                }
    
    
            }
            catch(err){
                alert("Login failed");
                reject(false);
            }
    
    
          
    
        })
    
    } 
    
    logout() {
        localStorage.removeItem('ds-token');
        localStorage.removeItem('currentUser');
    }
    
    public isAuthenticated(): boolean {
        
        let token = localStorage.getItem('ds-token');
        
        // Check whether the token is expired and return
        // true or false
        if (!this.jwtHelper.isTokenExpired(token)) {
          return true;
        }
    
        return false;
    }

}


export async function tokenGetter(req) {

    let authService = InjectorInstance.get(AuthService);
  
    let userCredentials = JSON.parse(localStorage.getItem("currentUser"));
    
    let token = localStorage.getItem("ds-token");

    if(authService.jwtHelper.isTokenExpired(token)){

        if(userCredentials){

            let token = await this.extapi.createToken({email: userCredentials.email, password: userCredentials.password});

            localStorage.setItem("ds-token", token);

            let result =  await this.extapi.getUserDetails({email:userCredentials.email});

            let currentUser: IUser = {
                displayName: result[0].username,
                email: userCredentials.email,
                fullName: result[0].full_name,
                profileImage: result[0].profile_image,
                client_id:result[0].client_id,
                address: result[0].address,
                date_of_birth: result[0].date_of_birth,
                phone_number: result[0].phone_number,
                date: result[0].date,
                password:result[0].password
            }
        
            this.profileService.setUser(currentUser);
        
            localStorage.setItem("currentUser",JSON.stringify(currentUser));

        }
        else{
            console.error("User credentials not found.")
            return localStorage.getItem("ds-token");
        }
        

    }
    else
        return localStorage.getItem("ds-token");
  
  
}
  
  
export function authScheme(req): string{

    return "Bearer ";

}
