<div class="container popup-cotroller" [style.width.px]="width ? width : 'initial'" [style.height.px]=" height ? height : 'initial'">

    <p-dialog [(visible)]="display" position="top" [draggable]="false" (onHide)="close()" [transitionOptions]="'200ms'">

        <p-header>
            DreamStakes Rules & Regulation
        </p-header>

        <div class="content-header">
            Choose Your Payment Method
        </div>

        <div>

            <p-radioButton name="groupname" value="cc" label="Credit & Debit Cards" [(ngModel)]="paymenttype"></p-radioButton>
            <br>
            <br>

            <div *ngIf="paymenttype == 'cc'" style="width: 410px;">

                <form class="cc-form" [formGroup]="form">

                    <div class="input-wrapper">

                        <span class="button-label">Card number</span>

                        <div class="ui-inputgroup">

                            <div class="form-group">

                                <ngx-cc defaultStyles placeholder="0000 0000 0000 0000" required="true" styleClass="default-input"
                                    name="cardNumber" formControlName="creditCard">
                                </ngx-cc>

                                <div class="card-err1"*ngIf="form.controls.creditCard.invalid && form.controls.creditCard.touched" >Card number is not valid</div>
                                

                            </div>

                        </div>

                    </div>


                    <div style="display: flex; justify-content: space-between;">

                        <div class="input-wrapper" style="width: 200px;">

                            <span class="button-label">Expiry date</span>
        
                            <div class="ui-inputgroup">

                                <div class="form-group">

                                    <ngx-cc-date defaultStyles styleClass="default-input" placeholder="MM / YY" formControlName="creditCardDate">
                                    </ngx-cc-date>

                                    <div class="card-err" *ngIf="form.controls.creditCardDate.invalid && form.controls.creditCardDate.touched">Expiration date is not valid</div>
                
                                </div>
        
                                
                                <span class="ui-inputgroup-addon">
                                    <img src="./assets/popup-btns/calender.png" style="width: 18px; height: 19px; position: relative; top: 4px;">
                                </span> 
        
                            </div>
        
                        </div>
        
                        <div class="input-wrapper" style="width: 200px;">
        
                            <span class="button-label">CVC/CVV</span>
        
                            <div  class="ui-inputgroup">

                                <div class="form-group">
                                    <ngx-cc-cvv defaultStyles styleClass="default-input" placeholder="CVV"
                                        formControlName="creditCardCvv"></ngx-cc-cvv>

                                        <div class="card-err" *ngIf="form.controls.creditCardCvv.invalid && form.controls.creditCardCvv.touched">Security code is not valid</div>
                                   
                                </div>
                                
                                <span class="ui-inputgroup-addon">
                                    <img src="./assets/popup-btns/info.png" style="width: 16px; height: 16px; position: relative; top: 4px;">
                                </span> 
                            </div>
                
                        </div>

                    </div>


                    <div class="input-wrapper">

                        <span class="button-label">Cardholder name</span>

                        <div class="ui-inputgroup" style="margin-bottom: 22px;">
                            
                            <div class="form-group">

                                <input type="text" pInputText placeholder="Enter cardholder’s full name" style="width: 100%;" class="common-txt" formControlName="creditCardHolder">
                                
                            </div>

                            <span class="ui-inputgroup-addon" style="padding-top: 22px;">
                                <img src="./assets/popup-btns/cc.png" style="width: 22px; height: 15px;">
                            </span> 

                        </div>

                    </div>


                    <div>
                        <p-radioButton name="groupname" value="val2" label="Save card"></p-radioButton>
                    </div>

                    <!-- <div style="margin-top: 25px; margin-bottom: 65px;">

                        <button type="submit"  [disabled]="form.invalid" style="
                            width: 374px;
                            height: 52px;
                            background: #F9A369;
                            border-radius: 10px;
                            border: none;
                            outline: none;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 17px;
                            line-height: 26px;
                            color: #FCFCFC;"
                            *ngIf="popupData?.customFooterButton?.includes('submit')"
                            (click)="addCard()"
                        >
                            Add card
                        </button>
                    </div>  -->

                </form>
                  
            </div>

            <p-radioButton name="groupname" value="paypal" label="Paypal" [(ngModel)]="paymenttype"></p-radioButton>

            <div *ngIf="paymenttype == 'paypal'" style="width: 475px;">

                <div class="input-wrapper">

                    <span class="button-label">Email address</span>

                    <div class="ui-inputgroup">
                        <input type="text" pInputText placeholder="Enter your paypal email address" class="common-txt">   
                        <span class="ui-inputgroup-addon" style="padding-top: 13px;">
                            <img src="./assets/popup-btns/cc.png" style="width: 22px; height: 15px;">
                        </span>  
                    </div>

                </div>


                <div>
                    <p-radioButton name="groupname" value="val2" label="Save paypal"></p-radioButton>
                </div>

                <div style="margin-top: 45px;">
                    <p-button label="Add paypal" class="p-button-warning"></p-button>
                </div>

            </div>

        </div>

        <p-footer>

            <div style="display: flex; justify-content: flex-end; width: 100%;">

                <button style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 235px;
                    height: 52px;
                    background:  #F9A369 ;  
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 26px;
                    color: #FCFCFC;
                    cursor: pointer;
                    border: none;
                    border-radius: 10px;
                    margin-top: 25px;"
                    [disabled]="form.invalid"
                    (click)="saveCardInfo()"
                >
                    Submit and Process
                </button>

            </div>
                
        </p-footer>

    </p-dialog>

</div>
