export interface IDotPref {
    color: string,
    defaultColor: string
}

export interface ISliderConfig {
    header: string,
    header2?: string,
    content: string,
    dotPeref?: IDotPref,
    default?: boolean
}

export enum IPopupCpmmands {
    close = 'close',
    next = 'next',
    back = 'back',
    showTearms = 'showTearms',
    showPrivacyPolicy = 'showPrivacyPolicy',
    submit = 'submit',
    reviewBid = 'reviewBid',
    nextDisable = 'nextDisable',
}

export interface IPopupConfigs {
    header?: string,
    contentHeader?: string,
    content: any,
    nxtButton?: boolean,
    bckButton?: boolean,
    customContents?: string,
    contentname?: string,
    customFooterButton?: string;
    showFooter?: boolean,
    nxtButtonDis?: boolean,
    selectedItemId?: number
}

export interface ICard {
    id?: string,
    icon: string,
    title: string,
    info: string,
    infoIcon: string,
    background?: string
}

export interface IInvites {
    email: string,
    status: string
}

export enum KeyLockerViews {
    entries = 'entries',
    cashSpent = 'cashSpent',
    invites = 'invites',
    bidPlaced = 'bidPlaced',
    contractWon = 'contractWon'
}

export enum settingPopupScreen {
    addNewCard = 'add-new-card',
    changePayPal = 'change-paypal',
    almostDone = 'almost-done',
    somethingWentWrong = 'something-went-wrong',
    smsVerify = 'sms-verify',
    emailVerify = 'email-verify'
}

export interface IUser {
    displayName: string,
    fullName: string,
    email: string,
    profileImage: string,
    client_id:number,
    address: string,
    date_of_birth: string,
    phone_number: string,
    date: string,
    password: string
    
}

export enum auctionHouseType {
    single = 1,
    duplex,
    comercial
    
}

export enum biddingType {
    fast = 1,
    custom
    
}

export enum claimeStatus {
    pending = 0,
    claimed
    
}
