<div class="container popup-cotroller">

    <p-dialog [(visible)]="display" position="top" [draggable]="false" (onHide)="sendCommandToParent('close')" [transitionOptions]="'200ms'">

        <p-header>
            Place Bid
        </p-header>

        <div class="place-bid" *ngIf="!openFinishBid">

            <div style="width: 100%; text-align: center;">

                <div style="font-family: 'Open Sans'; font-style: normal; font-weight: 700; font-size: 32px; line-height: 28px; color: #2C2C2C; margin-bottom: 15px;">
                   {{auctionData?.title}}
                </div>

                <div style="font-family: 'Open Sans'; font-style: normal; font-weight: 600; font-size: 20px; line-height: 28px; color: #2C2C2C; margin-bottom: 20px;">
                    {{auctionData?.type}} Auction House
                </div>

                <div style="font-family: 'Open Sans'; font-style: normal; font-weight: 400; font-size: 20px; line-height: 28px; color: #2C2C2C; margin-bottom: 40px;">
                    Current Bid : {{auctionData?.info.heighestBid}}
                </div>

            </div>

            <div style="width: 100%; height: 0px; border: 1px solid rgba(44, 44, 44, 0.2); margin-bottom: 56px;"></div>

            <div style="display: flex; flex-direction: row; align-items: center;">
            
            
                <div style="width: 515px;">
                
                    <img style="width: 100%; height: 555px;" src ="{{auctionData?.houseImage}}">

                </div>

                <div style="display: flex; flex-direction: column; flex-grow: 1; height: 555px; padding: 10px 0px 0px 50px;">
                
                    <div style="font-family: 'Open Sans'; font-style: normal; font-weight: 600; font-size: 20px; line-height: 28px; text-align: justify; color: #2C2C2C; margin-bottom: 30px;">
                        The bid you enter must be higher than the highest bid
                    </div>

                    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; max-width: 450px;">

                        <div style="font-family: 'Open Sans'; font-style: normal; font-weight: 600; font-size: 50px; line-height: 68px; text-align: center; color: #2C2C2C;">
                            $
                        </div>

                        <div style="width: 284px; height: 54px;">
                            <!-- <input type="text" pInputText class="common-txt" placeholder="Enter Bid"/> -->
                            <span class="ui-float-label" style="margin-left: -7px;">
                                <input id="float-input" type="number" size="30" min="1" pInputText class="common-txt" name="biddingAmount" ngDefaultControl [(ngModel)]="biddingAmount" (click)="bidButtonEnable()" style="width: 100%; padding: 15px;"> 
                                <label for="float-input">Enter Bid</label>
                            </span>

                            <div style="font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 22px;
                                text-align: start;
                                margin-top: 5px;
                                color: #FF0000;"
                            >
                                Highest Bid is {{highestBidForCustom}}$*
                            </div>

                        </div>

                        <div style="position: relative; top: -3px; width: 97px; height: 53px; border: none;" >
                        
                            <button [disabled]="disableBidBtn" (click)="bid()" style="display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%; height: 100%; background: #F9A369; border: none; border-radius: 10px; font-family: 'Be Vietnam Pro'; font-style: normal; font-weight: 600;
                                font-size: 16px;
                                line-height: 24px;
                                text-align: center;
                                color: #FFFFFF;"
                            >
                                Bid
                            </button>

                        </div>

                    </div>

                    <div class="details">

                        <div class="item">

                            <div class="title-area">
                
                                <label>
                                    Your Bid
                                </label>
                
                            </div>
                
                            <div class="val">
                                ${{lastBiddingAmount}}
                            </div>
                
                        </div>

                        <div class="hr-line"></div>

                        <div class="item">

                            <div class="title-area">
                
                                <label>
                                    Authentication Fee
                                </label>
                
                            </div>
                
                            <div class="val" [(ngModel)]="authenticationFee" ngDefaultControl name="authenticationFee">
                                Free
                            </div>
                
                        </div>

                        <div class="hr-line"></div>

                        <div class="item">

                            <div class="title-area">
                
                                <label style="font-weight: 600;">
                                    Total (USD)
                                </label>
                
                            </div>
                
                            <div class="val">
                                ${{total}}
                            </div>
                
                        </div>

                    </div>

                    <div style="max-width: 590px; display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 60px;">

                        <div style="font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 17px;
                            line-height: 26px;
                            text-decoration-line: underline;
                            color: #F9A369;"
                        >
                            Pay via Dream Coins
                        </div>

                        <div style="display: flex; flex-direction: row; justify-content: flex-end; flex-grow: 1;">
                            <!-- rgba(249, 163, 105, 0.5) -->
                            <button  [disabled]="disableReviewBidBtn" style="
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 170px;
                                height: 52px;
                                background:  #F9A369 ;  
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 500;
                                font-size: 17px;
                                line-height: 26px;
                                color: #FCFCFC;
                                cursor: pointer;
                                border: none;"
                                (click)="sendCommandToParent('reviewBid')"
                            >
                                Review Bid
                            </button>

                        </div>


                    </div>

                </div>

            </div>
        </div>

        <div class="finish-bid" *ngIf="openFinishBid">

            <app-finish-bid [auctionDetails]= "auctionData" [BiddingAmount] = "lastBiddingAmount" [heighestBid] = "totalBiddingAmount"></app-finish-bid>

        </div>

        <p-footer>

        </p-footer>

    </p-dialog>

</div>