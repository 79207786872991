<div class="card">

    <div class="icon-area">
        <img src="./assets/icons8_circled_user_female_skin_type_7 1.png">
        <div>24 Hours Services</div>
    </div>

    <div class="btn">
        <img src="./assets/small-icon.png">

        <div>
            <label>Serving All</label>
            <label>50 United States</label>
        </div>

    </div>

    <div class="dots-area">
        <div class="dot-1"></div>
        <div class="dot-2"></div>
        <div class="dot-3"></div>
    </div>

</div>
