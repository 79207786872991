import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { IPopupConfigs, IPopupCpmmands, ICard, auctionHouseType, IUser, biddingType, claimeStatus } from '../ds-components/ds-types';
import { ExtapiService } from '../extapi.service';
import * as moment from 'moment';
import { ProfileService } from '../profile.service';
import { IMessageStream, Interconnect } from 'ng-interconnect';
import { MainViews } from '../app.types';

const hrsForday = 24;
const minFordays = 1440;
const secForDays = 86400;
const minsForHr = 60;
const secForHrs = 3600;
const secsForMin = 60;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  
  private changeView: IMessageStream | Promise<IMessageStream>;

  @ViewChild('dragbtn') dragbtn: ElementRef;
  @ViewChild('drop') drop: ElementRef;
 
  @ViewChild('droptxt') droptxt: ElementRef;

  @Input() selectedAuctionIdx = 0;

  disableFastBitBtn: boolean = false;

  placeBid: boolean = false;
  currentPopopWindowIdx: number = 0;
  popupData: IPopupConfigs[];
  currentPopupPage: IPopupConfigs;

  timerDays = 0;
  timerHours = 0;
  timerMin = 0;
  timerSec = 0;

  arrowUp;
  dragPosition = { x: 0, y: 0 };

  cards: ICard[] = [
    {
      icon: './assets/dasboard/home.png',
      title: 'Auction Houses',
      info: '0',
      infoIcon: './assets/dasboard/info.png'
    },
    {
      icon: './assets/dasboard/users.png',
      title: 'Online Users',
      info: '0',
      infoIcon: ''
    },
    {
      icon: './assets/dasboard/settings.png',
      title: 'Last Winnig Bid',
      info: '$0',
      infoIcon: ''
    },
    {
      icon: './assets/dasboard/timer.png',
      title: 'Ending Soon',
      info: '0',
      infoIcon: ''
    }
  ]

  houses = []

  rooms:IRooms[] = [];

  selectedHouse;
  allAuctions = [];
  bidData;

  registedUsersDetails;

  isTimmerActivated: boolean = false;
  enableFastBidding: boolean = false;
  timerFn;


  disableCustomBidBtn:boolean = false;

  progress;
  progressBarbackgroundColor;
  allRegistedAuctions;
  selectedAuction;
  enableWinnerContainer : boolean = false;
  winner;
  getRegistedAuctionDetailsPerUserWithTimmerinfo;
  
  constructor(
    public appService: AppConfigService,
    public extapiService: ExtapiService,
    public profileService: ProfileService,
    private interconnect: Interconnect, 

    
    ) { 

      this.changeView = interconnect.connectToListener('leftbar/changeView', 'topbar');

      if (this.changeView['then']) {
        this.changeView['then']((notifier) => this.changeView = notifier);
      }

  }

  async ngOnInit() {

    clearTimeout(this.timerFn);

    //get all auction
    this.allAuctions = this.appService.allAuctions;

    //Get details of current user's registered auctions.
    this.allRegistedAuctions = await this.extapiService.getAllRegisteredAuctionsDetailsPerUser({clientId: this.profileService.currentUser.client_id});

    if(this.allRegistedAuctions.length){

       //If the user selects Dashboard without navigating through the Enter Screen dashboard.
       if(!this.appService.selectedAuction){

        this.appService.selectedAuction = this.allRegistedAuctions[0]

      }


      //get details of selected auction 
      // this.appService.selectedAuction =  this.appService.allAuctions[this.appService.selectedAuctionIdx];

     
      // may be user will navigate through the enter screen billboard. Then selected auction might be become an unregistered auction. We need to show only registered auction here
      let machingindexOfRegistedAuction = this.allRegistedAuctions.findIndex((el) => el.auction_id === this.appService.selectedAuction.auction_id);//find index of selected auction object from registed auction array
      
      if(machingindexOfRegistedAuction === -1){

      // this.appService.selectedAuctionIdx = 0;
      this.appService.selectedAuction =  this.allRegistedAuctions[0];

      }

      // else{

      //   this.appService.selectedAuctionIdx = machingindexOfRegistedAuction;
      // }

      //set card info >>>>> Auction Houses
      this.cards[0].info = this.appService.allAuctions.length.toString();

      //set card info >>>>> ending soon auctions count
      this.setCountOfEndingSoonAuction();

      // Set dropdown ---------------------------------------
      this.allAuctions.filter((anAuction) => {

        this.allRegistedAuctions.forEach((el) => {

          if(el.auction_id  === anAuction.auction_id){

            let auctionLabel = anAuction.title;

            this.houses.push({
              label: auctionLabel,
              value: {
                id: el.auction_id,
                name: auctionLabel
              }
            })

          }
          
        })

      });
      

      // this.selectedHouse = this.houses[this.appService.selectedAuctionIdx].value;
      this.selectedHouse = this.houses.find((el) => el.value.id === this.appService.selectedAuction.auction_id).value;

      //------------------------------------------------------
      // Set selected auction
      await this.setSelectedAuction();
      
      // Check if the user is winner
      if(await this.isCurrentUserWinner()){

        let winnerInfo = await this.extapiService.getWinnerInfo({
          "client_id": this.profileService.currentUser.client_id,
          "auction_id": this.selectedAuction.auction_id
        })

        this.winner = this.profileService.currentUser.fullName;
        this.selectedAuction.current_room = winnerInfo[0].room_num;
        this.enableWinnerContainer = true;

        //set card info >>>>> last winning bid
        this.cards[2].info = '$' + winnerInfo[0].winning_bid;

      }

      else{

        await this.activateTimmer();

        let foundSomeOtherBid = await this.isSomeoneMadeABid();

        this.disableFastBitBtn = foundSomeOtherBid;
        this.disableCustomBidBtn = foundSomeOtherBid;

        //set card info >>>>> last wining bid 
        
        //set previous Room number for selected auction
        let previousRoom = this.selectedAuction.current_room -1;

        // check auction type
        if(this.selectedAuction.type !== "Single"){

          if(previousRoom > 0){

             //get recode of selected auction previous room winner details
            let selectedAuctionPreviousRoomWinnerInfo : any = await this.extapiService.getWinningBidInfo({auction_id:this.selectedAuction.auction_id, room_num:previousRoom});

            if(selectedAuctionPreviousRoomWinnerInfo.length){

              this.cards[2].info = '$' + selectedAuctionPreviousRoomWinnerInfo[0].winning_bid;

            }

          }

        }
        

      }

    }

    else
      this.showEnterScreen();


  }

  async setSelectedAuction(){

    // let registeredAuction = this.allRegistedAuctions[this.appService.selectedAuctionIdx];
    // let allAuctionData = this.appService.allAuctions.find(el => el.auction_id === registeredAuction.auction_id);
    let allAuctionData = this.appService.allAuctions.find(el => el.auction_id === this.appService.selectedAuction.auction_id);
    let type;

    switch(allAuctionData.category){
      case auctionHouseType.single: type = "Single"; break;
      case auctionHouseType.duplex: type = "Duplex"; break;
      case auctionHouseType.comercial: type = "Commercial"; break;
    }

    this.selectedAuction = {
      auction_id: allAuctionData.auction_id,
      title: allAuctionData.title,
      houseImage:allAuctionData.coverimage,
      type: type,
      entrants: allAuctionData.entrants,
      current_room: allAuctionData.current_room,
      bidding_rooms: allAuctionData.bidding_rooms,
      biddingInfo: {
        desc: decodeURIComponent(escape(window.atob(allAuctionData.description)))
      },
      info: {
        startBid: allAuctionData.starting_bid,
        heighestBid: await this.getHeighestBid(allAuctionData.auction_id, allAuctionData.current_room),
        totalBids: await this.getNumbersOfBidsForAuction(allAuctionData.auction_id, allAuctionData.current_room),
      }
    }

  }

  async showPlaceBid(){

     //Get the last custom bid per user
    let LastCustomBidArr = await this.extapiService.getTheLastCustomBidPerUser({ 
      auction_id: this.selectedAuction.auction_id,
      client_id: this.profileService.currentUser.client_id,
      biddingType: biddingType.custom,
      current_room: this.selectedAuction.current_room
    });

    if(!LastCustomBidArr.length ){
      this.placeBid = true;
      return;
    }

    // get last costom bidding time
    let lastBiddingTime = moment(LastCustomBidArr[0].date).format(); 
    let now = moment().format();

    let differenceHrs = moment(now).diff(lastBiddingTime, 'hours');

    if(differenceHrs >= 8){

      this.placeBid = true;

    }

    else{

      this.disableCustomBidBtn = true;
      let nextBiddingTime = moment(LastCustomBidArr[0].date).add(8, 'hours').format('MMMM Do YYYY, h:mm:ss a'); 
      alert("You can place custom bid rightafter " +nextBiddingTime+" .");

    }

  }

  async setDropdown(){
    
    clearTimeout(this.timerFn);
    // this.appService.selectedAuctionIdx = this.allRegistedAuctions.findIndex(el => el.auction_id === this.selectedHouse.id);
    this.appService.selectedAuction = this.allRegistedAuctions.find(el => el.auction_id === this.selectedHouse.id);
    
    await this.setSelectedAuction();

    // Check if the user is winner
    if(await this.isCurrentUserWinner()){

      let winnerInfo = await this.extapiService.getWinnerInfo({
        "client_id": this.profileService.currentUser.client_id,
        "auction_id": this.selectedAuction.auction_id
      })

      this.winner = this.profileService.currentUser.fullName;
      this.selectedAuction.current_room = winnerInfo[0].room_num;
      this.enableWinnerContainer = true;

    }
    else{

      await this.activateTimmer();

      let foundSomeOtherBid = await this.isSomeoneMadeABid();

      this.disableFastBitBtn = foundSomeOtherBid;
      this.disableCustomBidBtn = foundSomeOtherBid;


    }


  }

  async recieveFromPopupDialog(e){
    
    switch(e.command){

      case IPopupCpmmands.close:
 
        this.currentPopopWindowIdx = 0;
        this.placeBid = false; 

        this.selectedAuction.info.heighestBid = await this.getHeighestBid(this.selectedAuction.auction_id, this.selectedAuction.current_room);
        this.selectedAuction.info.totalBids = await this.getNumbersOfBidsForAuction(this.selectedAuction.auction_id, this.selectedAuction.current_room);
        let foundSomeOtherBid = await this.isSomeoneMadeABid();

        this.disableFastBitBtn = foundSomeOtherBid;
        this.disableCustomBidBtn = foundSomeOtherBid;
        
      break;

      case IPopupCpmmands.reviewBid: this.currentPopopWindowIdx++; break;

    }

    this.currentPopupPage = this.popupData[this.currentPopopWindowIdx];

  }

  async dragEnd() {
    // console.log('end');
    var slideButton = this.drop.nativeElement;
    var arrowIcon = this.dragbtn.nativeElement;
  
    var slideButtonPosition = slideButton.getBoundingClientRect();
    var arrowIconPosition = arrowIcon.getBoundingClientRect();
  
   
    var slideButtonPositionCount = slideButtonPosition.x + slideButtonPosition.width;
    var arrowIconPositionCount = arrowIconPosition.x + arrowIconPosition.width;

    var centerPosition = slideButtonPositionCount - (slideButtonPosition.width /2)

    if (arrowIconPositionCount > centerPosition) {

        this.dragPosition = {
            x: 100,
            y: 0,
        };
        
        this.disableFastBitBtn = true;

        //fast bidding amount
        let biddingAmount = 50;
        let date = moment().format();

        //add bidding details to database
        await this.extapiService.addBid({
          auction_id: this.selectedAuction.auction_id,
          client_id: this.profileService.currentUser.client_id,
          bid: biddingAmount,
          current_room: this.selectedAuction.current_room,
          biddingType: biddingType.fast,
          date: date});

          //Change dashboad details

          this.selectedAuction.info.heighestBid = await this.getHeighestBid(this.selectedAuction.auction_id, this.selectedAuction.current_room);
          this.selectedAuction.info.totalBids = await this.getNumbersOfBidsForAuction(this.selectedAuction.auction_id, this.selectedAuction.current_room);
          

          this.disableCustomBidBtn = await this.isSomeoneMadeABid();

        }

        else {

            this.dragPosition = {
                x: 0,
                y: 0,
            };

            this.disableFastBitBtn = false;
        }
  }

  GrowAreaDropdownText(){
     
    var content = this.droptxt.nativeElement;

    if (content.style.display === "block") {

      content.style.display = "none";
      this.arrowUp = false;

    } 

    else {
      content.style.display = "block";
      this.arrowUp = true;
    }
  
  }

  changePosition() {

    this.dragPosition = {
      x: 100,
      y: 0,
    };

    console.log(this.dragPosition)
  }

  async getHeighestBid(auctionId, currentRoom){

    //Get the highest bid in the current auction
    let heighestBidArr = await this.extapiService.getHighestBidforAuction({auctionId: auctionId, current_room: currentRoom});

    let heighestBid = heighestBidArr[0]?.heighestBid || 0;
    return heighestBid;


  }
  
  async getNumbersOfBidsForAuction(auctionId, currentRoom){

    let totalBidsArr = await this.extapiService.getNumbersOfBidsForAuction({auctionId: auctionId, current_room: currentRoom});
    return totalBidsArr[0]?.NumberOfBids || 0;


  }

  async isSomeoneMadeABid(): Promise<boolean>{

    let LastFastBidDetails = await this.extapiService.getTheLastBidDetails({auctionId: this.selectedAuction.auction_id, current_room: this.selectedAuction.current_room});

    let LastFastBiderId = LastFastBidDetails[0]?.client_id || 0;

    return !(LastFastBiderId !== this.profileService.currentUser.client_id);

  }

  private setProgressBar(){

    //set presentage for participatory capacity of the current auction
    this.progress = ((this.registedUsersDetails[0].NumberOfUsers / this.selectedAuction.entrants) * 100).toFixed(2);

  }
  
  async activateTimmer(){

    //Get number of registed users in current auction
    this.registedUsersDetails = await this.extapiService.getNumbersOfUsersForRegistedAuction({auctionId: this.selectedAuction.auction_id});

    //Checking if the maximum number of registrants are currently registered for the selected auction.
    if(this.registedUsersDetails[0].NumberOfUsers === this.selectedAuction.entrants){

      //Get selected auction ended time
      let getEndedDateTime = await this.extapiService.getEndedTime({auction_id: this.selectedAuction.auction_id, room_number: this.selectedAuction.current_room});

      if(getEndedDateTime.length){

        this.isTimmerActivated = true;

        let endedDateTime = moment(getEndedDateTime[0].ended_time).format(); //now
        let now = moment().format();

        await this.configTimerAndButtons(endedDateTime, now);

        this.timerFn = setInterval(() => this.clockTicking(), 1000);

      }

      else{

        this.setProgressBar();
        this.enableWinnerContainer = false;

      }

      
    }

    else{

      this.disableCustomBidBtn = true;
      this.isTimmerActivated = false;
      this.setProgressBar();
      this.enableWinnerContainer = false;
    

    }

  }
  
  private async configTimerAndButtons(endedDateTime, now){

    //Get the difference between now and the end of the auction

    let endDays = moment(endedDateTime).diff(now, 'days');
    let endHrs = moment(endedDateTime).diff(now, 'hours');
    let endMins = moment(endedDateTime).diff(now, 'minutes');
    let endSecs = moment(endedDateTime).diff(now, 'seconds');

    let lastBidDetailsArr = await this.extapiService.getTheLastBidDetails({auctionId: this.selectedAuction.auction_id, current_room: this.selectedAuction.current_room});

    if(endDays <= 0 && endHrs <= 0 && endMins <= 0 && endSecs <= 0){

      if(!lastBidDetailsArr.length){

        this.timerDays = 0;
        this.timerHours = 0;
        this.timerMin = 0;
        this.timerSec = 0;
  
        this.disableCustomBidBtn = true;
        this.disableFastBitBtn = true;
  
        this.enableWinnerContainer = false;
        return;
  
      }

      
      return;
    }

    this.enableWinnerContainer = false;
    this.enableFastBidding = true;
    this.disableCustomBidBtn = false;

    this.timerDays = moment(endedDateTime).diff(now, 'days');

    // ------------------- Calc hrs ---------------------------
    let numOfHrs = moment(endedDateTime).diff(now, 'hours');
    this.timerHours = numOfHrs - (this.timerDays * hrsForday);
    

    //--------------------- Calc mins -------------------------
    let minsDiff = moment(endedDateTime).diff(now, 'minutes');

    //Reduce no. of days and calc no. of mins
    let days = Math.floor(minsDiff / minFordays);
    let mins = minsDiff - (minFordays * days);

    //Reduce hrs
    let hrs = Math.floor(mins / minsForHr);
    this.timerMin = mins - (minsForHr * hrs);

    //--------------------------------------------------------

    //--------------------- Calc seconds ---------------------
    let secondsDiff = moment(endedDateTime).diff(now, 'seconds');

    //Reduce no. of days
    days = Math.floor(secondsDiff / secForDays);
    let secs = secondsDiff - (secForDays * days);

    //Reduce hrs
    hrs = Math.floor(secs / secForHrs);
    secs = secs - (secForHrs * hrs);

    //reduce mins
    mins = Math.floor(secs / secsForMin);
    this.timerSec = secs - (secsForMin * mins);

  }

  async clockTicking(){
	
    if(this.timerHours <= 0 && this.timerMin <= 0 && this.timerSec <= 0){
      clearTimeout(this.timerFn);
      this.enableFastBidding = false;
      this.disableCustomBidBtn = true;
      await this.setWinner();
      return;
    }


    if(this.timerSec)
      this.timerSec --;

      
   
    if(this.timerSec === 0){

      if(this.timerMin){
        this.timerMin --;
        this.timerSec = 59;
      }
      

    }

    if(this.timerMin === 0){
    
      if(this.timerHours){
        this.timerHours --;
        this.timerMin = 59;
      }
       
    }

  

 
  }

  async setWinner(){

    let lastBidDetailsArr = await this.extapiService.getTheLastBidDetails({auctionId: this.selectedAuction.auction_id, current_room: this.selectedAuction.current_room});

    let heighestBidArr = await this.extapiService.getHighestBidforAuction({auctionId: this.selectedAuction.auction_id, current_room: this.selectedAuction.current_room});

    // Check if the winner is already recorded
    let winnerInfo = await this.extapiService.getWinnerInfo({
      "client_id": lastBidDetailsArr[0].client_id,
      "auction_id": lastBidDetailsArr[0].auction_id
    })

    let userDetails = await this.extapiService.getUserDetailsFromId({clientId: lastBidDetailsArr[0].client_id});
    this.winner = userDetails[0].full_name;

    if(!winnerInfo.length){

      await this.extapiService.setWinnerDetails({
        "client_id": lastBidDetailsArr[0].client_id,
        "auction_id": lastBidDetailsArr[0].auction_id,
        "title": this.selectedAuction.type + " Auction",
        "room_num": lastBidDetailsArr[0].current_room,
        "username": this.winner,
        "winning_bid": heighestBidArr[0].heighestBid,
        "winning_date": moment(lastBidDetailsArr[0].date).format(),
        "claime_status": claimeStatus.pending
      })


      //check auction house type
      if(this.selectedAuction.type === "Single"){

        this.enableWinnerContainer = true;

      }
      else{

        if(this.selectedAuction.current_room < this.selectedAuction.bidding_rooms){

          await this.extapiService.updateCurrentRoom({auction_id: this.selectedAuction.auction_id, current_room: this.selectedAuction.current_room + 1});

          // get previous room end time and set next room end time
          let getEndedDateTime = await this.extapiService.getEndedTime({auction_id: this.selectedAuction.auction_id, room_number: this.selectedAuction.current_room});
                    
          //send aucion timmer activation details to backend
          let endedTime = moment(getEndedDateTime[0].ended_time).add(21, 'days').format();
          await this.extapiService.auctionTimmerActivation({
            auction_id: this.selectedAuction.auction_id,
            room_number: this.selectedAuction.current_room + 1,
            started_time: moment().format(),
            ended_time: endedTime 
          });

          this.selectedAuction.current_room++;
          this.selectedAuction.info.heighestBid = 0;
          this.selectedAuction.info.totalBids = 0;
          this.enableWinnerContainer = false;
          this.enableFastBidding = true;
          this.disableFastBitBtn = false;
          this.disableCustomBidBtn = false;

          // Set timer
          let endedDateTime = moment(endedTime).format(); //now
          let now = moment().format();

          await this.configTimerAndButtons(endedDateTime, now);
          this.timerFn = setInterval(() => this.clockTicking(), 1000);

        }


      }

    }
    else{

      //check auction house type
      if(this.selectedAuction.type === "Single"){

        this.enableWinnerContainer = true;

      }
      else{

        this.enableWinnerContainer = false;
        this.disableFastBitBtn = false;
        this.disableCustomBidBtn = false;

      }

    }


  }

  showEnterScreen(){
    (this.changeView as IMessageStream).emit({viewId: MainViews.enterScreen, showBackground: true, showCards:false});
  }


  private async isCurrentUserWinner(){

    let winnerInfo = await this.extapiService.getWinnerInfo({
      "client_id": this.profileService.currentUser.client_id,
      "auction_id": this.selectedAuction.auction_id
    })

    return winnerInfo.length;

  }


  async setCountOfEndingSoonAuction(){

    this.getRegistedAuctionDetailsPerUserWithTimmerinfo = await this.extapiService.getRegistedAuctionDetailsPerUserWithTimmerinfo({client_id: this.profileService.currentUser.client_id});

    let endingSoonAuctionArr = [];

    for(let auction of this.getRegistedAuctionDetailsPerUserWithTimmerinfo){

      let getEndedDateTime = await this.extapiService.getEndedTime({auction_id: auction.auction_id, room_number: auction.room_number});
      let endedDateTime = moment(getEndedDateTime[0].ended_time).format();
      let now = moment().format()
      var tommorow =  moment().add(1, 'days').format()

      //check auction is closer to close
      if(moment(endedDateTime).isSameOrAfter(now)){

        if(moment(endedDateTime).isBefore(tommorow)){

          endingSoonAuctionArr.push({
            auction_id:  auction.auction_id,
            time: endedDateTime
          })

        }

      }

    }

    this.cards[3].info = endingSoonAuctionArr.length.toString()

    console.log(this.selectedHouse)


  }


}

interface IRooms {
  auction_id:number,
  houseImage: string,
  title: string,
  entrants:string,
  current_room: number,
  info: {
    startBid: string,
    heighestBid: number,
    totalBids: number
  },
  biddingInfo: {
    desc: string
  },
  type: any
}
