import { Component, OnInit } from '@angular/core';
import { IUser } from 'src/app/ds-components/ds-types';
import { ProfileService } from 'src/app/profile.service';
import * as moment from 'moment';
import { AppConfigService } from 'src/app/app-config.service';
import { ExtapiService } from 'src/app/extapi.service';

@Component({
  selector: 'app-right-panal',
  templateUrl: './right-panal.component.html',
  styleUrls: ['./right-panal.component.scss']
})
export class RightPanalComponent implements OnInit {

  currentUser: IUser;
  joining_date;
  allRegistedAuctions;
  entries;
  bidsPlaced;
  ContractsWon;
  cashSpend;



  constructor(private profileService: ProfileService,
    public extapiService: ExtapiService,
    public appService: AppConfigService) { 

    this.currentUser = profileService.getUser();
    
    this.joining_date = moment(this.currentUser.date).format("MMM Do YYYY");
    
    
  }

  async ngOnInit(): Promise<void> {

    //Get details of current user's registered auctions and set entries
    this.allRegistedAuctions = await this.extapiService.getAllRegisteredAuctionsDetailsPerUser({clientId: this.profileService.currentUser.client_id});
    this.entries = this.allRegistedAuctions.length;

    //get all bids per user
    let bidsPlacedArr = await this.extapiService.getAllBidsPerUser({clientId: this.profileService.currentUser.client_id});
    this.bidsPlaced = bidsPlacedArr.length;

    //get wining details per current user
    let WinningDetailsArr = await this.extapiService.getWinningDetailsPerUser({client_id: this.profileService.currentUser.client_id});
    this.ContractsWon = WinningDetailsArr.length;

     //get wining details per current user
     this.cashSpend = "$" + this.allRegistedAuctions.reduce((a, c) => {return a + c.enter_fee}, 0).toLocaleString();

    
  }

}
